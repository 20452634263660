import React, { FunctionComponent } from 'react';
import cn from 'classnames';

import { IPreviewButtonProps } from './types';

import styles from './styles.module.scss';

const PreviewButton: FunctionComponent<IPreviewButtonProps> = ({
  previewClass,
  text,
  onClick,
  disabled,
  disabledTitle,
  mode,
}) => {
  return (
    <button
      className={cn({
        [styles.icon]: true,
        [styles[previewClass]]: true,
        [styles.selected]: mode === text.toLocaleLowerCase(),
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
      title={disabled ? disabledTitle : undefined}
    >
      {text}
    </button>
  );
};

export default PreviewButton;
