import { AVAILABLE_COUNTRIES, DEFAULT_COUNTRY_CODE, timeZones } from './constants';

export const getCountryCode = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countryCode = timeZones.find((zone) => {
    return zone.timeZone === userTimeZone;
  })?.code;
  if (countryCode) {
    return AVAILABLE_COUNTRIES.includes(countryCode) ? countryCode : DEFAULT_COUNTRY_CODE;
  }
  return DEFAULT_COUNTRY_CODE;
};

export const getBackendHost = (): string => {
  if (process.env.REACT_APP_OVERRIDE_HOST) {
    return process.env.REACT_APP_OVERRIDE_HOST;
  }

  return process.env.REACT_APP_BACKEND_HOST!;
};

export const getHostPrefix = () => {
  return getBackendHost().split('.yoc.com')[0];
};
