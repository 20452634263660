import { E_AD_CATEGORY_NAME } from '@common/enums';
import { IShowcaseCollection, IShowroomCollection } from '@common/types';
import { E_COUNTRY_CODE, E_CUSTOM_CODE } from '@utils/location/constants';

import { ADS_COLLECTION_AT } from './ads-collection-at';
import { ADS_COLLECTION_CH } from './ads-collection-ch';
import { ADS_COLLECTION_DE } from './ads-collection-de';
import { ADS_COLLECTION_FI } from './ads-collection-fi';
import { ADS_COLLECTION_HQ } from './ads-collection-hq';
import { ADS_COLLECTION_PHARMA } from './ads-collection-pharma';
import { ADS_COLLECTION_PL } from './ads-collection-pl';
import { ADS_COLLECTION_TEST } from './ads-collection-test';
import { ADS_COLLECTION_TRAVEL } from './ads-collection-travel';
import { ADS_COLLECTION_YZA } from './ads-collection-yza';

export const ADS_COLLECTION: {
  [key in E_COUNTRY_CODE | E_CUSTOM_CODE]: IShowroomCollection | IShowcaseCollection;
} = {
  at: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_AT,
  },
  ch: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_CH,
  },
  de: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_DE,
  },
  fi: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_FI,
  },
  hq: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_HQ,
  },
  pharma: {
    type: 'collection',
    ads: ADS_COLLECTION_PHARMA,
    tag: 'Showroom | Pharma & OTC',
  },
  pl: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_PL,
  },
  test: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_TEST,
  },
  yza: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_YZA,
    defaultCategory: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
  },
  travel: {
    type: 'collection',
    ads: ADS_COLLECTION_TRAVEL,
    tag: 'Showroom | Tourism',
    defaultCategory: E_AD_CATEGORY_NAME.YOC_SKIN,
  },

};
