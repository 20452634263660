import React, { CSSProperties, FunctionComponent } from 'react';
import cn from 'classnames';

import { IArrowProps } from './types';

import style from './styles.module.scss';

export const ArrowButton: FunctionComponent<IArrowProps> = ({
  direction,
  className,
  color,
  disabled,
  scale,
  onClick,
}) => {
  const scaledArrow: CSSProperties = {};
  if (scale) {
    (scaledArrow as any)['--scale'] = `scale(${scale})`;
  }
  if (color) {
    (scaledArrow as any)['--arrow-color'] = color;
  }

  return (
    <div
      style={scaledArrow}
      className={cn(className, style.arrow, style[direction], { [style.disabled]: disabled, [style.scale]: scale })}
      data-testid="ArrowButton"
      onClick={onClick}
    ></div>
  );
};
