import { ChangeEvent, Fragment, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';

import { E_AD_CATEGORY_NAME, E_EFFECT } from '@common/enums';
import { useIsIFrameLoaded, useWindowResize } from '@common/hooks';
import { E_AD_PRODUCT_KEY } from '@common/types';
import { CAROUSEL_VERTICAL_MODE_WIDTH, FEATURE_FLAG_DMEXCO } from '@components/GalleryCarousel/configs';
import { PreviewScreen } from '@components/PreviewScreen';
import { routes } from '@navigation/router';
import { slotId2URL } from '@pages/PreviewPageSlotId/helpers';
import { formatDate } from '@utils/index';

import { WebsiteEntry } from '../../../../back/src/types';
import {
  EFFECT_TITLE_NAME,
  MODE_DESKTOP_HIDDEN_EFFECTS,
  MODE_MOBILE_HIDDEN_EFFECTS,
} from './constants';
import { getGalleryPreviewUrl, isCookieSet, isEffectShownOnDesktop, isEffectShownOnMobile } from './helpers';
import { E_SCREENSHOT_INFO_MESSAGE, IUseGalleryMode, TDisplayMode } from './types';

import style from './styles.module.scss';

export const useScreenshot = (
  slotId: string,
  mode: TDisplayMode,
  enableScreenshot: boolean,
  publisherList: WebsiteEntry[],
  product: string | undefined,
  iframeRef: RefObject<HTMLIFrameElement>
) => {
  const isIframeLoaded = useIsIFrameLoaded(iframeRef);
  const [isDownloadStarted, setIsDownloadStarted] = useState(false);
  const [isIframeCaptured, setIsIframeCaptured] = useState(true);
  const [disabledTitleDownload, setDisabledTitleDownload] = useState(E_SCREENSHOT_INFO_MESSAGE.WAITING);
  const [publisherKey, setPublisherKey] = useState(publisherList[0].key);
  const [delay, setDelay] = useState(0);

  useEffect(() => {
    setPublisherKey(publisherList[0].key);
  }, [mode, publisherList]);

  const screenshot = async (scrollValue: number) => {
    const dateTime = formatDate(new Date());
    const data = {
      publisher: slotId2URL({
        slotId,
        previewMode: mode,
        isScreenshotEnabled: isCookieSet && enableScreenshot,
        publisherKey,
      }),
      scroll: scrollValue,
      device: mode,
      delay,
    };

    try {
      const response = await axios.post(process.env.REACT_APP_SCREENSHOT_URL!, data);
      // NOTE: in order to test screenshot in local, this repo should be downloaded and running:
      // https://github.com/yoc-ag/screenshot-backend and REACT_APP_SCREENSHOT_URL_DEV should be used.
      // const response = await axios.post(process.env.REACT_APP_SCREENSHOT_URL_DEV!, data);
      const linkSource = `data:image/jpg;base64,${response.data.base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `screenshot_${slotId}_${publisherKey}_${scrollValue}_${dateTime}.jpg`;
      downloadLink.click();
      window.removeEventListener('message', onIframeMessage, false);
    } catch (error) {
      console.error(error);
    }
    setIsIframeCaptured(true);
    setIsDownloadStarted(false);
  };

  const onIframeMessage = async (event: MessageEvent) => {
    if (event.origin !== '*' && typeof event.data === 'number' && isIframeCaptured && isIframeLoaded) {
      setIsIframeCaptured(false);
      await screenshot(event.data);
    }
  };

  const onScreenshot = async () => {
    if (!isDownloadStarted && isIframeLoaded) {
      setDisabledTitleDownload(E_SCREENSHOT_INFO_MESSAGE.DOWNLOADING);
      setIsDownloadStarted(true);
      iframeRef.current?.contentWindow?.postMessage('eventMessage', '*');
      window.addEventListener('message', onIframeMessage, false);
    }
  };

  const onPublisherSelect = async (event: ChangeEvent<HTMLSelectElement>) => {
    const newPublisherKey = event.target.value;
    iframeRef.current!.src = slotId2URL({
      slotId,
      previewMode: mode,
      isScreenshotEnabled: isCookieSet && enableScreenshot,
      publisherKey,
    });
    setPublisherKey(newPublisherKey);
  };

  const onDelayChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDelay(Number(event.currentTarget.value));
  }, []);

  return {
    delay,
    disabledTitleDownload,
    isDownloadStarted,
    isIframeLoaded,
    onDelayChange,
    onPublisherSelect,
    onScreenshot,
    publisherKey,
    publisherList,
  };
};

export const usePreview = (
  slotId: string,
  initialMode: TDisplayMode,
  isShowroom: boolean,
  enableScreenshot: boolean,
  effect?: E_EFFECT
) => {
  const [mode, setMode] = useState<TDisplayMode>(initialMode);
  const urlPreview = slotId2URL({
    slotId,
    previewMode: mode,
    isScreenshotEnabled: isCookieSet && enableScreenshot,
  });

  useEffect(() => {
    setMode(initialMode);
  }, [slotId, initialMode]);

  return {
    mode,
    qrUrl: urlPreview.replace('?yoc_preview', ''),
    setMode: (previewMode: TDisplayMode) => {
      setMode(previewMode);
      if (!isShowroom) {
        routes.preview({ slotId, mode: previewMode, effect }).replace();
      }
    },
  };
};

export const usePreviewGallery = (props: IUseGalleryMode) => {
  const { adData, qrUrl, iframeRef, effect, isShowroom } = props;

  const restrictShowcasePreview = adData?.restrictShowcasePreview;
  const allowEffects: E_EFFECT[] = useMemo(() => {
    if (!adData?.allowEffects) {
      return [];
    }

    let hiddenEffects: E_EFFECT[] = [E_EFFECT.NONE_IN_SITEBAR, E_EFFECT.NONE_IN_STICKY];

    // Define hidden effects based on restrictShowcasePreview
    if (restrictShowcasePreview === 'mobile-only') {
      hiddenEffects = MODE_MOBILE_HIDDEN_EFFECTS;
    } else if (restrictShowcasePreview === 'desktop-only') {
      hiddenEffects = MODE_DESKTOP_HIDDEN_EFFECTS;
    }

    return adData.allowEffects.filter((allowEffect) => {
      return !hiddenEffects.includes(allowEffect);
    });
  }, [adData?.allowEffects, restrictShowcasePreview]);

  const [hasQrCodeEffect, setHasQrCodeEffect] = useState(false);
  const [hasPreviewSwitchEffect, setHasPreviewSwitchEffect] = useState(false);
  const [currentEffect, setCurrentEffect] = useState<E_EFFECT | undefined>(effect || (allowEffects && allowEffects[0]));
  const [isCarouselVerticalMode, setIsCarouselVerticalMode] = useState(false);

  // TODO: Temporary feature - https://yocmobile.atlassian.net/browse/DC-328
  const [isDMEXCOMode, setIsDMEXCOMode] = useState(false);
  useEffect(() => {
    if (Cookie.get(FEATURE_FLAG_DMEXCO) && !isShowroom) {
      setIsDMEXCOMode(true);
      document.body.classList.add(style['demo-DMEXCO']);
    } else {
      setIsDMEXCOMode(false);
      document.body.classList.remove(style['demo-DMEXCO']);
    }
  }, [isShowroom]);

  const [windowWidth] = useWindowResize();
  useEffect(() => {
    if (windowWidth > 0 && windowWidth <= CAROUSEL_VERTICAL_MODE_WIDTH && !isCarouselVerticalMode) {
      setIsCarouselVerticalMode(true);
    }

    if (windowWidth > CAROUSEL_VERTICAL_MODE_WIDTH && isCarouselVerticalMode) {
      setIsCarouselVerticalMode(false);
    }
  }, [isCarouselVerticalMode, windowWidth]);

  const isCarouselMode = useMemo(() => {
    return Boolean(allowEffects && allowEffects.length > 0);
  }, [allowEffects]);

  const gallerySlides = useMemo(() => {
    if (isCarouselMode && allowEffects) {
      const createSlide = ({
        isMobileDeviceShown,
        isDesktopDeviceShown,
        allowEffect,
        title,
      }: {
        isMobileDeviceShown: Boolean;
        isDesktopDeviceShown: Boolean;
        allowEffect?: E_EFFECT;
        title?: string;
      }) => {
        if (!isMobileDeviceShown && !isDesktopDeviceShown) {
          return null;
        }

        return (
          <Fragment key={allowEffect || title}>
            <div className={style['slide-preview']}>
              {isMobileDeviceShown && (
                <PreviewScreen
                  mode={'mobile'}
                  url={getGalleryPreviewUrl({
                    currentEffect,
                    url: qrUrl,
                    isDMEXCOMode,
                    effect: allowEffect,
                    product: adData?.productKey,
                  })}
                  iframeRef={iframeRef}
                />
              )}
              {isDesktopDeviceShown && (
                <PreviewScreen
                  mode={'desktop'}
                  url={getGalleryPreviewUrl({
                    currentEffect,
                    url: qrUrl,
                    isDMEXCOMode,
                    effect: allowEffect,
                    isMobile: false,
                    product: adData?.productKey,
                  })}
                  iframeRef={iframeRef}
                />
              )}
            </div>
            <span className={style['slide-title']}>
              {EFFECT_TITLE_NAME[allowEffect as keyof typeof EFFECT_TITLE_NAME] || title}
            </span>
          </Fragment>
        );
      };

      // Create slides for each effect
      const slides = allowEffects.reduce((acc: JSX.Element[], allowEffect) => {
        const isMobileDeviceShown = isEffectShownOnMobile(allowEffect, restrictShowcasePreview);
        const isDesktopDeviceShown = isEffectShownOnDesktop(allowEffect, restrictShowcasePreview);

        const slide = createSlide({ allowEffect, isMobileDeviceShown, isDesktopDeviceShown });

        if (slide) {
          acc.push(slide);
        }
        return acc;
      }, []);

      // Add a special slide for the YUVS product if necessary
      if (adData?.productKey.toLocaleLowerCase() === E_AD_PRODUCT_KEY.YUVS) {
        slides.push(
          createSlide({
            title: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
            isMobileDeviceShown: true,
            isDesktopDeviceShown: true,
          })!
        );
      }

      return slides;
    }

    return null;
  }, [
    isCarouselMode,
    allowEffects,
    adData?.productKey,
    currentEffect,
    qrUrl,
    isDMEXCOMode,
    iframeRef,
    restrictShowcasePreview,
  ]);

  const onGalleryChange = useCallback((index: number) => {
    if (allowEffects) {
      const gallerySlideEffect = allowEffects[index];
      setCurrentEffect(gallerySlideEffect);

      const isSwitcherEnabled = isEffectShownOnDesktop(gallerySlideEffect, restrictShowcasePreview);
      if (isSwitcherEnabled !== hasPreviewSwitchEffect) {
        setHasPreviewSwitchEffect(isSwitcherEnabled);
      }

      const isQrEnabled = isEffectShownOnMobile(gallerySlideEffect, restrictShowcasePreview);
      if (isQrEnabled !== hasQrCodeEffect) {
        setHasQrCodeEffect(isQrEnabled);
      }
    }
  }, [allowEffects, hasPreviewSwitchEffect, hasQrCodeEffect, restrictShowcasePreview]);

  return useMemo(() => {
    return {
      currentEffect,
      hasGallerySlidePreviewSwitch: isCarouselMode && hasPreviewSwitchEffect,
      hasGallerySlideQrCode: isCarouselMode && hasQrCodeEffect,
      isCarouselMode,
      isCarouselVerticalMode,
      isDMEXCOMode,
      gallerySlides,
      onGalleryChange,
    };
  }, [
    currentEffect,
    hasPreviewSwitchEffect,
    hasQrCodeEffect,
    isCarouselMode,
    isCarouselVerticalMode,
    isDMEXCOMode,
    gallerySlides,
    onGalleryChange,
  ]);
};
