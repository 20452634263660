/* eslint-disable max-lines */
/* eslint-disable max-len */
import { WebsiteList } from '../../../../back/src/types';

export const PUBLISHER_WEBSITE_LIST: WebsiteList = [];
PUBLISHER_WEBSITE_LIST.push({
  title: 'autoscout24.at',
  key: 'www_autoscout24_at',
  url: 'https://www.autoscout24.at/lst?sort=standard&desc=0&ustate=N,U&atype=C&cy=A',
  injectTagBefore: '<div class="ListItem_wrapper__J_a_C">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith: '<style> #gdpr-consent-tool-wrapper {display: none !important}</style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'tele.at',
  key: 'www_tele_at',
  url: 'https://www.tele.at/sendungsdetails/13203272/ku-damm-63.html',
  injectTagBefore: '<div id="adSpecial" class="adContainer">',
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'nachrichten.at',
  key: 'www_nachrichten_at',
  url: 'https://www.nachrichten.at/',
  injectTagBefore: '<div id="renderSlot_Top_mobil" class="container__col--mdHide">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .container__row>div.container__col--lg8, .container__row>aside {display: none;} </style></head>',
    },
  ],
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'noen.at',
  key: 'm_noen_at',
  url: 'https://m.noen.at/niederoesterreich/politik/co-workingspace-plaene-fuer-60-dorf-offices-in-niederoesterreich-niederoesterreich-print-co-working-space-homeoffice-dorf-office-267138317',
  injectTagBefore: '<div class="col-4 ads ads--yoc">',
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'boerse-express.com',
  key: 'www_boerse-express_com',
  url: 'https://www.boerse-express.com',
  injectTagBefore: '<div class="mobile-only">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .sky-left {display:none !important;} .sky-right {display: none !important;} .impression-tracker { display: none !important;} .superbanner-container { display:none !important;}</style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'immobilienscout24.at',
  key: 'www_immobilienscout24_at',
  url: 'https://www.immobilienscout24.at',
  injectTagBefore: '<div osa-wallpaper-click-area="" class="at-ad-bill ad-overflow-visible">',
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'nachrichten.at/wirtschaft',
  key: 'nachrichten_at-wirtschaft',
  url: 'https://www.nachrichten.at/wirtschaft/',
  injectTagBefore: '<div class="container container__row">\n    <div class="',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .container__row>div.container__col--lg8, .container__row>aside {display: none;} </style></head>',
    },
  ],
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'woman.at',
  key: 'woman_at',
  url: 'https://woman.at/',
  injectTagBefore: '<div class="page-left"',
  platform: {
    mobile: true,
    desktop: false,
  },
});

// PUBLISHER_WEBSITE_LIST.push({
//   title: '1000ps.at',
//   key: '1000ps_at',
//   url: 'https://www.1000ps.at/',
//   injectTagBefore: '<div class="container-billboard d-flex">',
//   replaceContent: [
//     {
//       searchFor: '</head>',
//       replaceWith:
//         '<style> .container-sky, .cadbox.mb-3 {display: none;} </style></head>',
//     },
//   ],
// });

PUBLISHER_WEBSITE_LIST.push({
  title: 'styleupyourlife.at/life',
  key: 'styleupyourlife_at-life',
  url: 'https://www.styleupyourlife.at/life',
  injectTagBefore: '<div class="content-section--main">',
  platform: {
    mobile: false,
    desktop: false,
  },
});
PUBLISHER_WEBSITE_LIST.push({
  title: 'sn.at/sport',
  key: 'sn_at-sport',
  url: 'https://www.sn.at/sport/',
  injectTagBefore: '<div class="box-three-or-six">',
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'gebrauchtwagen.at/angebote',
  key: 'gebrauchtwagen_at-angebote',
  url: 'https://www.gebrauchtwagen.at/angebote/',
  injectTagBefore: '<div class="css-pb11bt">',
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'kurier.at/family',
  key: 'kurier_at-family',
  url: 'https://kurier.at/family',
  injectTagBefore: '<main class="main">',
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'gutekueche.at',
  key: 'gutekueche_at',
  url: 'https://www.gutekueche.at/',
  injectTagBefore: '<section class="sec">\n                                    <h2>REZEPT ARTEN</h2>',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith: '<style> div#skyscraper-cont, div#ad_cont_superbanner { display: none; } </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'szene1.at',
  key: 'szene1_at',
  url: 'https://www.szene1.at/',
  injectTagBefore: '<h3>Singlesuche</h3>',
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'transfermarkt.at',
  key: 'transfermarkt_at',
  url: 'https://www.transfermarkt.at/',
  injectTagBefore: '<div class="row padding shadow" id="newstickerbox">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith: '<style> #billboard {display: none !important;}</style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'immosuchmaschine.at/suche',
  key: 'immosuchmaschine_at-suche',
  url: 'https://www.immosuchmaschine.at/suche/',
  injectTagBefore: '<div class="placeholder-box">',
  replaceContent: [
    {
      searchFor: '<div data-visx data-ad-unit="903678" data-is-mobile="1" data-page-url=""></div>',
      replaceWith: '<!-- <div data-visx data-ad-unit="903678" data-is-mobile="1" data-page-url=""></div> -->',
    },
    {
      searchFor: '</head>',
      replaceWith: '<style> .placeholder-box {display: none !important;} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'immodirekt.at',
  key: 'immodirekt_at',
  url: 'https://www.immodirekt.at/',
  injectTagBefore: '<section class="',
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'bazar.at/immobilien',
  key: 'bazar_at-immobilien',
  url: 'https://www.bazar.at/l/07-immobilien/c',
  injectTagBefore: '<a _ngcontent-sc113="" bb-ad-list-item=""',
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'nachrichten.at/reisen',
  key: 'nachrichten_at-reisen',
  url: 'https://www.nachrichten.at/meine-welt/reisen/',
  injectTagBefore: '<section class="content__container content__container--mediaelement">',
  // charset: 'iso-8859-1',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .container__row>div.container__col--lg8, .container__row>aside {display: none;} </style></head>',
    },
  ],
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'sn.at/reisen',
  key: 'sn_at-reisen',
  url: 'https://www.sn.at/leben/reisen/',
  injectTagBefore: '<div class="box-three-or-six">',
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'sn.at/kultur',
  key: 'sn_at-kultur',
  url: 'https://www.sn.at/kultur/',
  injectTagBefore: '<div class="box-three-or-six">',
  platform: {
    mobile: true,
    desktop: true,
  },
});

/* PUBLISHER_WEBSITE_LIST.push({
  title:'starzip.de',
  key: 'starzip_de',
  url: 'https://www.starzip.de/',
  injectTagBefore: '<div class="td-main-content-wrap td-main-page-wrap">',
});*/

PUBLISHER_WEBSITE_LIST.push({
  title: 'at.fem.com',
  key: 'at_fem_com',
  url: 'https://www.at.fem.com/',
  injectTagBefore: '<div class="bm-block bm-title bm-vt-divider divider-title',
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'madonna.com',
  key: 'madonna_com',
  url: 'https://www.madonna.com/',
  injectTagBefore: '<div class="l-contentContainer contentContainer">',
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'sixx.at',
  key: 'sixx_at',
  url: 'https://www.sixx.at/',
  injectTagBefore: '<div style="opacity:1">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> span.ad-slot-container.skyscraper-ad-slot-container.narrow-wrapper { display: none; } span.ad-slot-container.billboard-ad-slot-container.narrow-wrapper.ad-render-space { display: none; } </style></head>',
    },
  ],
  platform: {
    mobile: false,
    desktop: false,
  },
});
PUBLISHER_WEBSITE_LIST.push({
  title: 'gemeinsamerleben.com',
  key: 'gemeinsamerleben_com',
  url: 'https://gemeinsamerleben.com/',
  injectTagBefore: '<div class="position-relative hero-section',
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'gmx.at',
  key: 'gmx_at',
  url: 'https://www.gmx.at/',
  injectTagBefore: '<div data-content>',
  platform: {
    mobile: false,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'tv-media.at',
  key: 'tv-media_at',
  url: 'https://www.tv-media.at/',
  injectTagBefore:
    '<div><div class="pl-4 sm:pl-8 lg:pl-16"><div class="relative z-10 mb-3 flex items-center font-herokid text-xl font-bold text-white"',
  replaceContent: [
    {
      searchFor: "api.renderSlot('Top1');",
      replaceWith: "//api.renderSlot('Top1');",
    },
    {
      searchFor: "api.renderSlot('Top_mobile');",
      replaceWith: "//api.renderSlot('Top_mobile');",
    },
    {
      searchFor: '</head>',
      replaceWith:
        '<style> aside#right2 { display: none; } div#Left1 { display: none !important; } div#ad-top { display: none; } div[attr=data-yoc-ads] { margin: 0 !important } </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'sn.at/freizeit',
  key: 'sn_at-freizeit',
  url: 'https://www.sn.at/freizeit/',
  injectTagBefore: '<div class="box-three-or-six">',
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'nachrichten.at/panorama/society',
  key: 'nachrichten_at-panorama-society',
  url: 'https://www.nachrichten.at/panorama/society/',
  injectTagBefore: '<div class="container container__row">\n    <div class="',
  // charset: 'iso-8859-1',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .container__row>div.container__col--lg8, .container__row>aside {display: none;} </style></head>',
    },
  ],
  platform: {
    mobile: false,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'kicker.de',
  key: 'kicker_de',
  url: 'https://www.kicker.de/',
  injectTagBefore: `<ul>\n
  <li class="kick__img200">`,
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        "<style> div[class^='kick__area--main'] {margin-top:0px !important;} div[id^='hpto_'], div[class^='kick__ad-pos_wrapper'], div[class^='smart-skin-main'], div[class^='smart-skin-top'], div[id^='skin_container'] {display: none !important; visibility: hidden !important;} </style></head>",
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'kuechengoetter.de',
  key: 'kuechengoetter_de',
  url: 'https://www.kuechengoetter.de/',
  injectTagBefore: '<main id="main" class="main-container">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        "<style> div[class^='advertisement__'], div[class^='advertisement'], div[id^='sas_'], div[id^='sticky-'], div[class^='jpx-'] {display: none !important; visibility: hidden !important;} </style></head>",
    },
    {
      searchFor: '<script src="/build/legacy/runtime.4a22aa73.js"',
      replaceWith: '<script src="/build/legacy/runtime.4a22aa73.js"></script>',
    },
    {
      searchFor: '<script src="/build/legacy/0.6ac39e56.js"',
      replaceWith: '<script src="/build/legacy/0.6ac39e56.js"></script>',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'fernsehserien.de',
  key: 'fernsehserien_de',
  url: 'https://www.fernsehserien.de/',
  injectTagBefore: '<section>',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        "<style> main[class^='flexer'] > div {width: calc(100%) !important;} ins[class^='werbemittel'], main[class^='flexer'] > aside, div[class^='smart-'], .cmpwrapper {display: none !important; visibility: hidden !important;} body {overflow: auto !important;} </style></head>",
    },
    {
      searchFor: 'https://bilder.fernsehserien.de/fernsehserien.de/fs-2021/js/main.min-20221130142802.js',
      replaceWith: 'https://bilder.fernsehserien___.de/fernsehserien.de/fs-2021/js/main.min-20221130142802.js',
    },
    {
      searchFor: 'fernsehserien.de/fernsehserien.de/fs-2021/js/cp/IMF_FS_Framework-20221031111643.js',
      replaceWith: 'fernsehserien___.de/fernsehserien.de/fs-2021/js/cp/IMF_FS_Framework-20221031111643.js',
    },
    {
      searchFor: 'https://cp.fernsehserien.de/now.js',
      replaceWith: 'https://cp.fernsehserien____.de/now.js',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'rp.pl',
  key: 'rp_pl',
  url: 'https://www.rp.pl/dane-gospodarcze/art37390361-rada-polityki-pienieznej-zaskoczyla-inwestorow-i-oslabila-zlotego',
  injectTagBefore: '<picture>\n<source media="(min-width: 1353px)"',
  replaceContent: [
    {
      searchFor: 'https://statics.rp.pl/dist/js/static/prebid6.11.0.js?aver=1ff57d0f',
      replaceWith: 'https://statics.rp___.pl/dist/js/static/prebid6.11.0.js?aver=1ff57d0f',
    },
    {
      searchFor: 'https://rp.hit.gemius.pl/xgemius.js',
      replaceWith: 'https://rp.hit.gemius___.pl/xgemius.js',
    },
    {
      searchFor: '</head>',
      replaceWith:
        "<style> .content--video--commercial, .ad--page--breaker, #rodo-popup, .row>[class^='w-'], div[class^='mb-5'] {display: none !important; visibility: hidden !important;} </style></head>",
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'kobieta.wp.pl',
  key: 'kobieta_wp_pl',
  url: 'https://kobieta.wp.pl/ilona-wisniewska-norwegowie-obserwuja-co-dzieje-sie-na-granicy-6831757109660352a',
  injectTagBefore: '<!-- react-empty: 362 -->',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        "<style> body {background-color: white;} div[data-reactid='596'], div[data-reactid='363'], div[data-reactid='368'], div[data-reactid='382'], div[data-reactid='376'], div[data-reactid='390'], div[data-reactid='398'], div[data-reactid='405'], div[data-reactid='407'], div[data-reactid='443'], div[data-reactid='470'], div[data-reactid='474'], div[data-reactid='458'], div[data-reactid='493'], div[data-reactid='516'], div[data-reactid='587'], div[class^='g2MDbV6M'], div[class*='g1LYrY1P'], div[class*='g2We4eiD'], div[data-reactid='502'] > div:nth-child(4), div[class^='b1gx6gq d1iwt3y i1j79g1'], div[class^='b3n6b2q d3p5xpz i3pge21'], div[class^='b2m673s d2o5tr0 i2oga33'], div[class^='i2n2kmm h2ncdvm e2mvpfs g2l3e5d eho3m0d48m a7fm3ax m5lh8pl'] {display: none !important; visibility: hidden !important;} </style></head>",
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'next.gazeta.pl',
  key: 'next_gazeta_pl',
  url: 'https://next.gazeta.pl/next/7,151003,29125088,kursy-walut-10-11-kurs-dolara-funta-euro-franka-rubla.html',
  injectTagBefore: '',
  charset: 'ISO-8859-2',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith: '<style> {display: none !important; visibility: hidden !important;} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'dziennikzachodni.pl',
  key: 'dziennikzachodni_pl',
  url: 'https://dziennikzachodni.pl/transport-maszyny-tbm-po-slaskich-drogach-demontuja-czesci-infrastruktury-na-a4-i-a1-by-zmiescil-sie-tam-ogromny-pojazd/ar/c1-17032141',
  injectTagBefore: '<section class="atomsContentSubheading component"',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .atomsAdsCellModel, .atomsSlotsWrapperAnchorAd, .atomsArticleStickyVideo {display: none !important; visibility: hidden !important;} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'sport.pl',
  key: 'sport_pl',
  url: 'https://www.sport.pl/pilka/7,65082,29123435,mueller-specyficznie-pogratulowal-lewandowskiemu-tym-gestem.html#s=SNO_Img_3',
  injectTagBefore: '<div class="art_embed">',
  charset: 'ISO-8859-2',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith: '<meta name="viewport" content="width=device-width, initial-scale=0.5"> </head>',
    },
    {
      searchFor: '</head>',
      replaceWith: '<style>  {display: none !important; visibility: hidden !important;} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'autokult.pl',
  key: 'autokult_pl',
  url: 'https://autokult.pl/ostatni-dzwonek-przed-problemami-sprawdz-bo-rano-nie-odpalisz,6827846782564896a',
  injectTagBefore: `<style>.bGysFtsl{
    min-height: inherit;
    height: inherit;
    position: inherit;
    display: inherit;
    flex: 0 0 100%;
  }</style>`,
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .blurClass, [blurClass]{overflow:auto !important;} .blurClass>*, [blurClass]>* {filter: none !important;} .CF3BoardLayer {display: none !important; visibility: hidden !important;} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'wiadomosci.gazeta.pl',
  key: 'wiadomosci_gazeta_pl',
  url: 'https://wiadomosci.gazeta.pl/wiadomosci/7,114883,29136515,imgw-ostrzega-przed-gesta-mgla-beda-utrudnienia-w-ruchu.html#do_t=49&do_g=12&do_s=A&do_w=46&do_v=723&do_st=RS&do_sid=1014&do_a=1014&s=BoxNewsImg5&do_upid=723_ti&do_utid=29136515&do_uvid=1668411854346',
  injectTagBefore: '<p class="art_paragraph">',
  charset: 'ISO-8859-2',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        "<style> body{background-color: white;} #adUnit-101-TOPBOARD-MOBI, #adUnit-104-RECTANGLE-MOBI, div[id^='banC'], .onnOnDemand, div[class*='adviewDFPBanner'], div[class*='activeBan'], div[class^='ban001_wrapper'], div[class*='yb_recovery'] {display: none !important; visibility: hidden !important;} </></head>",
    },
  ],
  platform: {
    mobile: false,
    desktop: true,
  },
});

/* PUBLISHER_WEBSITE_LIST.push({
  title: 'zueriost.ch',
  key: 'zueriost_ch',
  url: 'http://www.zueriost.ch',
  injectTagBefore: `<div class="main-content">`,
  replaceContent: [
    {
      searchFor: '/sites/default',
      replaceWith: 'http://zueriost.ch/sites/default',
    },
  ],
  platform: {
    mobile: false,
    desktop: true,
  },
});
*/

PUBLISHER_WEBSITE_LIST.push({
  title: 'gmx.ch',
  key: 'gmx_ch',
  url: 'https://www.gmx.ch/',
  injectTagBefore: '<div data-content>',
  platform: {
    mobile: false,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'watson.ch',
  key: 'watson_ch',
  url: 'https://www.watson.ch/',
  injectTagBefore: '<div class="cluster">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .cluster {padding-bottom: 20px !important;}   .cluster > .region.x_full_desktop.x_full_tablet.commercial, .dfp_ad, .cookiefooter {display: none !important;}  </style></head>',
    },
  ],
  platform: {
    mobile: false,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'watson.ch/fr',
  key: 'watson_ch-fr',
  url: 'https://www.watson.ch/fr/',
  injectTagBefore: '<div class="cluster">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .cluster {padding-bottom: 20px !important;}   .cluster > .region.x_full_desktop.x_full_tablet.commercial, .dfp_ad, .cookiefooter {display: none !important;}  </style></head>',
    },
  ],
  platform: {
    mobile: false,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'bergfex.ch',
  key: 'bergfex_ch',
  url: 'https://www.bergfex.ch/',
  injectTagBefore: '<div class="grid cols2">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith: '<style> .section-left {padding-top: 25px !important;}  </style></head>',
    },
  ],
  platform: {
    mobile: false,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'ronorp.net',
  key: 'ronorp_net',
  url: 'https://www.ronorp.net/',
  injectTagBefore: '<main style="z-index: 1;" class="inspiration topics ">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .bg_grey.clearfix.mrec-holder, .banner_right, .clearer {display: none !important; visibility: hidden !important;} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'grenchnertagblatt.ch',
  key: 'grenchnertagblatt_ch',
  url: 'https://www.grenchnertagblatt.ch/',
  injectTagBefore: '<div id="contdoc-1cosquq860-1277674" never="" class="elementwrap">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .resor, .resor--relative {display: none !important; visibility: hidden !important;} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'swisswebcams.ch',
  key: 'swisswebcams_ch',
  url: 'https://m.swisswebcams.ch/?hl=de',
  injectTagBefore: '<div class="ad">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        "<style> div[class^='ad'] > div, div[class='mys-wrapper'], div[class='grippy-host'], ins[class^='adsbygoogle'], div[class^='googleInFeedAdContainer'], div[class^='header_ad'] {display: none !important; visibility: hidden !important;} </style></head>",
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'finews.ch',
  key: 'finews_ch',
  url: 'https://finews.ch',
  injectTagBefore: '<div id="mobile-top">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        "<style> div[id='mobile-top'] > div[class^='moduletable'], div[class^='moduletable specialads'] {display: none !important; visibility: hidden !important;} div[id^='page'] { padding-bottom: 20px; } </style></head>",
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: '4-4-2.com',
  key: '4-4-2_com',
  url: 'https://www.4-4-2.com/',
  injectTagBefore: '<div class="main-slideshow">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        "<style> div[class^='ad-banner'] {display: none !important; visibility: hidden !important;} </style></head>",
    },
  ],
  platform: {
    mobile: true,
    desktop: false,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'immmo.at/Wien',
  key: 'immmo_at-immo-Haus-Wien',
  url: 'https://www.immmo.at/immo/Haus/Wien',
  injectTagBefore:
    '<li data-detail="false" data-realestateuid="1315322234" data-hostname="www.findmyhome.at" data-hostuid="29097984" class="wrapper-result">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> body {background-color: white;} #save-profile-modal, .wrapper-ad-skyscraper, .wrapper-ad-top, .wrapper-ad-content, .wrapper-bottom-ad, #IMM_M_Top, #IMM_R_Mid, .lean-overlay, #IMM_R_List2, #IMM_R_Bot  {display: none !important; visibility: hidden !important;} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'vienna.at/news',
  key: 'vienna_at-specials-news',
  url: 'https://www.vienna.at/specials/news',
  injectTagBefore: '<div class="vodl-ad">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith: '<base href="https://www.vienna.at"></head>',
    },
    {
      searchFor: '</head>',
      replaceWith:
        '<style> body{background-color: white;} #rm-adslot-superlargead_1, .tee-popup, #rm-adslot-bigsizebanner_1, #rm-adslot-skyscraper_1, #rm-adslot-contentad_1, #rm-adslot-banner_1 {display: none !important; visibility: hidden !important;} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'news.at',
  key: 'news_at',
  url: 'https://www.news.at',
  injectTagBefore: '<div class="stageWidgetsHome">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .GATracking_Stageteaser {background: #fff !important;} .GATracking_Stageteaser:nth-child(1) div[id^="yoc"] {float: none !important; background:none !important;} div[id*="adCont_"], div#onetrust-consent-sdk, div[class^="ad_con_out"], div[class^="ad_con"] {display: none !important} body {overflow: scroll !important}</style>',
    },
  ],
  platform: {
    mobile: false,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'puls4.com',
  key: 'puls4_com',
  url: 'https://www.puls4.com/',
  injectTagBefore: '<div class="styles_loading-spinner__3pclN">',
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'tvheute.at',
  key: 'tvheute_at',
  url: 'https://tvheute.at/',
  injectTagBefore: '<div id="showListContainer" class="swipeable" data-group="swipe-list">',
  replaceContent: [
    {
      searchFor: '</head>',
      replaceWith:
        '<style> .backdrop, body>aside .content, div[class^="adspace"], #CybotCookiebotDialog {display:none !important} body {background-color: white !important} header {z-index:10000 !important} </style></head>',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'woman.at/woman-magazin',
  key: 'woman_at_woman-magazin',
  url: 'https://woman.at/woman-magazin',
  injectTagBefore: '<div class="ym-grid page-first">',
  replaceContent: [],
  platform: {
    mobile: false,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'woman.at/woman-magazin',
  key: 'mobile_woman_at_woman-magazin',
  url: 'https://woman.at/woman-magazin',
  injectTagBefore: '<div class="ym-grid page-first">',
  replaceContent: [],
  platform: {
    mobile: true,
    desktop: false,
  },
  userAgent:
    'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1',
});

/* PUBLISHER_WEBSITE_LIST.push({
  title: 'derstandard.at',
  key: 'derstandard_at',
  url: 'https://www.derstandard.at/',
  injectTagBefore: `<section data-id="s1" class="ad1 ad4">`,
  replaceContent: [],
  platform: {
    mobile: true,
    desktop: true,
  },
}); */

PUBLISHER_WEBSITE_LIST.push({
  title: 'krone.at',
  key: 'krone_at',
  url: 'https://www.krone.at/',
  injectTagBefore: '<div class="container-fluid footer-wrapper">',
  replaceContent: [],
  platform: {
    mobile: false,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'lustaufsleben.at',
  key: 'lustaufsleben_at',
  url: 'https://www.lustaufsleben.at/',
  injectTagBefore: '<section class="teaser">',
  replaceContent: [
    { searchFor: '<aside class="ad_con ad_con_desktop">', replaceWith: '' },
    {
      searchFor: '<div class="ad_con ad_con_desktop" id="Right1">',
      replaceWith: '',
    },
  ],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'familienleben.ch',
  key: 'familienleben_ch',
  url: 'https://www.familienleben.ch/',
  injectTagBefore: '<div id="front-focusteaser" class="section">',
  replaceContent: [],
  platform: {
    mobile: true,
    desktop: true,
  },
});

PUBLISHER_WEBSITE_LIST.push({
  title: 'k.at',
  key: 'k_at',
  url: 'https://k.at',
  injectTagBefore: '<div id="div-gpt-ad-kat_responsive2">',
  replaceContent: [],
  platform: {
    mobile: true,
    desktop: true,
  },
});

/* PUBLISHER_WEBSITE_LIST.push({
  title: 'sat1.de',
  key: 'sat1_de',
  url: 'https://video.sat1.de/',
  injectTagBefore: `<div >`,
  replaceContent: [],
  platform: {
    mobile: true,
    desktop: true,
  },
}); */

/* PUBLISHER_WEBSITE_LIST.push({
  title: '',
  key: '',
  url: '',
  injectTagBefore: '',
});*/
