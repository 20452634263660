import React, { LiHTMLAttributes, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { Showroom } from '@common/types';
import { Preview } from '@components/Preview';
import { TDisplayMode } from '@components/Preview/types';
import { ShowroomSidebarGroupedAd } from '@components/ShowroomSidebarGroupedAd';
import { routes } from '@navigation/router';
import { IShowroomPageProps } from '@pages/Showroom/types';

import { getSlotIdActiveCategory } from './helpers';

import style from './styles.module.scss';

export type CollectionSection = {
  category: string;
  ads: Showroom[];
};

const ShowroomPage = ({ route, initSlotId, collectionData, adsData }: IShowroomPageProps) => {
  const [routerInitialized, setRouterInitialized] = useState(false);
  const [displayModeOverride, setDisplayModeOverride] = useState<TDisplayMode | null>(null);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [isCategoryExpanded, setIsCategoryExpanded] = useState(true);

  // preload images
  useEffect(() => {
    if (collectionData) {
      (collectionData.ads as Showroom[]).forEach((ad) => {
        if (ad.backgroundUrl) {
          new Image().src = ad.backgroundUrl;
        }

        if (ad.logoUrl) {
          new Image().src = ad.logoUrl;
        }
      });
    }
  }, [collectionData]);

  useEffect(() => {
    if (collectionData) {
      if (typeof initSlotId === 'string') {
        const activeCategoryData = getSlotIdActiveCategory(
          route.params.slotId ?? initSlotId,
          collectionData.ads as Showroom[]
        );
        setActiveCategory(activeCategoryData);

        setRouterInitialized(true);
      }
    }
  }, [collectionData, initSlotId, route.params.slotId]);

  const groupedAds: CollectionSection[] = useMemo(() => {
    if (!collectionData) {
      return [];
    }

    const collectionAds = collectionData.ads as Showroom[];
    const grouped = collectionAds.reduce((acc, currentValue) => {
      const groupKey = currentValue.category;
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, {} as { [key: string]: Showroom[] });

    const categoryOrder: string[] = [];

    for (const ad of collectionAds) {
      if (!categoryOrder.includes(ad.category)) {
        categoryOrder.push(ad.category);
      }
    }

    return categoryOrder.map((category) => {
      return {
        category,
        ads: grouped[category],
      };
    });
  }, [collectionData]);

  if (!collectionData) {
    return <p>No collection found</p>;
  }

  if (!routerInitialized) {
    return null;
  }

  const ads = collectionData.ads!;
  const adIdx = ads.findIndex((ad) => {
    return ad.slotId === route.params.slotId;
  });

  if (adIdx === -1) {
    return null;
  }

  const currentAd = ads[adIdx];

  let displayMode = currentAd.displayMode;
  let isModeSwitchAllowed = false;

  if (adsData) {
    const adData = adsData[currentAd.slotId];
    if (adData) {
      // TODO: remove all this logic when going for DC-363 remove displayMode
      const previewtype = typeof adData.previewType !== 'string' ? 'mobile' : adData.previewType;
      const restrictshowcasepreview =
        typeof adData.restrictShowcasePreview !== 'string' ? 'mobile-and-desktop' : adData.restrictShowcasePreview;

      if (restrictshowcasepreview === 'mobile-and-desktop') {
        isModeSwitchAllowed = true;
        if (previewtype === 'mobile') {
          displayMode = 'mobile';
        } else if (previewtype === 'desktop') {
          isModeSwitchAllowed = false;
          displayMode = 'desktop';
        }
      }
    }
  }
  // remove stops here

  const onToggleCategory = (category: string) => {
    if (activeCategory === category) {
      setActiveCategory(null);
      setIsCategoryExpanded(false);
    } else {
      setActiveCategory(category);
      setIsCategoryExpanded(true);
      let newSlotId;

      for (const item of groupedAds) {
        if (item.category === category) {
          newSlotId = item.ads[0].slotId;
        }
      }

      setDisplayModeOverride(null);

      routes
        .collection({
          collectionId: route.params.collectionId,
          slotId: newSlotId,
        })
        .push();
    }
  };

  const onAdSelect: LiHTMLAttributes<HTMLLIElement>['onClick'] = (event) => {
    event.stopPropagation();

    setDisplayModeOverride(null);

    routes
      .collection({
        collectionId: route.params.collectionId,
        slotId: event.currentTarget.dataset.slotid!,
      })
      .push();
  };

  return (
    <div
      className={style.showroom}
      data-testid="showroomCollectionContainer"
    >
      <div className={cn(style.sidebar, { [style.active]: isCategoryExpanded })}>
        <div className={style['sidebar-holder']}>
          {groupedAds.map((group) => {
            return (
              <ShowroomSidebarGroupedAd
                key={group.category}
                group={group}
                onAdSelect={onAdSelect}
                activeSlotId={route.params.slotId}
                onToggleCategory={onToggleCategory}
                isExpanded={group.category === activeCategory}
                mobileVersion={false}
              />
            );
          })}
        </div>
      </div>

      <div className={style.content}>
        <Preview
          slotId={currentAd.slotId}
          isModeSwitchAllowed={isModeSwitchAllowed}
          displayHeader={false}
          isShowroom
          initialMode={displayModeOverride ? displayModeOverride : displayMode}
          adData={adsData ? adsData[currentAd.slotId] : null}
          product={adsData?.[currentAd.slotId]?.productKey}
        />
      </div>
    </div>
  );
};

export default ShowroomPage;
