import React, { useEffect } from 'react';

import { routes, useRoute } from '@navigation/router';
import CookiePage from '@pages/CookiePage';
import NoMatchPage from '@pages/NoMatch';
import PreviewPageSlotId from '@pages/PreviewPageSlotId';
import Showroom from '@pages/Showroom';
import { getCountryCode } from '@utils/location';

import './themes/neutral.module.scss';

const App = () => {
  const route = useRoute();

  useEffect(() => {
    switch (route.name) {
      // redirect from root to country-specific (based on time zone) route
      case 'home':
        routes
          .collection({
            collectionId: getCountryCode(),
          })
          .replace();
        break;
      default:
    }
  }, [route.name]);

  return (
    <>
      <div className="logo" data-testid="yoc-logo" />
      {route.name === 'collection' && <Showroom />}
      {route.name === 'preview' && <PreviewPageSlotId route={route} />}
      {route.name === 'cookie' && <CookiePage route={route} />}
      {route.name === false && <NoMatchPage />}
    </>
  );
};

export default App;
