import Cookie from 'js-cookie';

import { BASE_PREVIEW_URL } from '@common/configs';
import { E_EFFECT } from '@common/enums';
import { E_AD_PRODUCT_KEY, IAdData } from '@common/types';

import { PUBLISHER_WEBSITE_LIST } from './configs';
import { MODE_DESKTOP_HIDDEN_EFFECTS, MODE_MOBILE_HIDDEN_EFFECTS, NARROW_DESKTOP_PREVIEW_EFFECTS } from './constants';
import { IGetGalleryPreviewUrl, TDisplayMode } from './types';

export const isCookieSet = Cookie.get('_yss') === 'true';

// Filtering out the publishers which support given display mode (mobile/desktop) for screenshotting.
export const filterPublisherList = (mode: TDisplayMode) => {
  return PUBLISHER_WEBSITE_LIST.filter((publisher) => {
    return publisher.platform[mode];
  });
};

export const getGalleryPreviewUrl = ({
  currentEffect,
  url,
  isDMEXCOMode,
  isMobile = true,
  effect,
  product,
}: IGetGalleryPreviewUrl) => {
  url += '?yoc_preview';

  const dmexcoForceQuery = isDMEXCOMode ? (isMobile ? '&yoc_force_smartphone' : '&yoc_force_desktop') : '';

  // NOTE: Ad additional parameter (DC-445)
  let narrowDesktopPreviewParam = '';
  if (effect && NARROW_DESKTOP_PREVIEW_EFFECTS.includes(effect)) {
    narrowDesktopPreviewParam = '&desktopWidth=640';
  }

  // Remove product parameter from the URL if the product is YUVS and effect is not set.
  if (product?.toLowerCase() === E_AD_PRODUCT_KEY.YUVS && currentEffect === undefined) {
    const prefix = url.includes('?') ? '&' : '?';
    url += `${prefix}product=${E_AD_PRODUCT_KEY.YUVS}`;
  }

  if (!currentEffect && product?.toLocaleLowerCase() !== E_AD_PRODUCT_KEY.YUVS) {
    return isMobile ? `${BASE_PREVIEW_URL}/mobile` : `${BASE_PREVIEW_URL}/desktop-showroom`;
  }
  if (isMobile) {
    if (currentEffect === effect && effect) {
      url = `${url.replace('desktop-showroom', 'mobile')}&effect=${effect}${dmexcoForceQuery}`;
    }
  } else {
    url =
      currentEffect === effect
        ? `${url.replace('mobile', 'desktop-showroom')}${
          effect ? `&effect=${effect}` : ''
        }${dmexcoForceQuery}${narrowDesktopPreviewParam}`
        : // if the effect is sitebar, pass /?effect=sitebar to load empty sitebar layout.
        effect === E_EFFECT.SITEBAR
          ? `${BASE_PREVIEW_URL}/desktop-showroom/?effect=${effect}`
          : `${BASE_PREVIEW_URL}/desktop-showroom`;
  }

  return url;
};

export const buildPreviewUrl = ({
  qrUrl,
  isCarouselMode = false,
  currentEffect,
  useForPreview = false,
  isFullscreenUrl = false,
  product,
}: {
  qrUrl: string;
  isCarouselMode?: Boolean;
  currentEffect?: E_EFFECT | undefined;
  useForPreview?: Boolean;
  isFullscreenUrl?: Boolean;
  product?: string;
}) => {
  if (useForPreview) {
    qrUrl += '?yoc_preview';
  }

  // Check if the product is YUVS and the effect is not set
  // if so, add the product parameter to the URL
  if (product?.toLowerCase() === E_AD_PRODUCT_KEY.YUVS && currentEffect === undefined) {
    const prefix = qrUrl.includes('?') ? '&' : '?';
    qrUrl += `${prefix}product=${E_AD_PRODUCT_KEY.YUVS}`;
  }

  // Add effect parameter to the URL if the effect is set
  if (currentEffect && isCarouselMode) {
    const prefix = qrUrl.includes('?') ? '&' : '?';
    qrUrl += `${prefix}effect=${currentEffect}`;
  }

  if (isFullscreenUrl) {
    qrUrl = qrUrl.replace('mobile', 'desktop-showroom');
  }

  return qrUrl;
};

export const isEffectShownOnMobile = (
  effect: E_EFFECT,
  restrictShowcasePreview?: IAdData['restrictShowcasePreview']
): boolean => {
  return !MODE_MOBILE_HIDDEN_EFFECTS.includes(effect) && restrictShowcasePreview !== 'desktop-only';
};

export const isEffectShownOnDesktop = (
  effect: E_EFFECT,
  restrictShowcasePreview?: IAdData['restrictShowcasePreview']
): boolean => {
  return !MODE_DESKTOP_HIDDEN_EFFECTS.includes(effect) && restrictShowcasePreview !== 'mobile-only';
};
