import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Cookie from 'js-cookie';
import { Route } from 'type-route';

import { BASE_PREVIEW_URL } from '@common/configs';
import { IAdDataBase, IShowcaseCollection, IShowroomCollection, Showroom } from '@common/types';
import { FEATURE_FLAG_DMEXCO } from '@components/GalleryCarousel/configs';
import { routes } from '@navigation/router';
import { getSlotIdActiveCategory } from '@pages/ShowroomPage/helpers';

export const useGroupedAds = (collectionData: IShowroomCollection | IShowcaseCollection | null) => {
  return useMemo(() => {
    if (!collectionData) {
      return [];
    }

    const ads = collectionData.ads as Showroom[];
    const categoryOrder: string[] = [];
    const grouped = ads.reduce((acc, currentValue) => {
      const groupKey = currentValue.category;
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, {} as { [key: string]: Showroom[] });

    ads.forEach((ad) => {
      if (!categoryOrder.includes(ad.category)) {
        categoryOrder.push(ad.category);
      }
    });
    return categoryOrder.map((category) => {
      return {
        category,
        ads: grouped[category],
      };
    });
  }, [collectionData]);
};

export const useCategory = (
  initSlotId: string,
  collectionData: IShowroomCollection | IShowcaseCollection | null,
  route: Route<typeof routes.collection>
) => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const onToggleCategory = useCallback((category: string) => {
    setActiveCategory(activeCategory === category ? null : category);
  }, [activeCategory]);

  useEffect(() => {
    if (collectionData && typeof initSlotId === 'string') {
      const activeCategoryData = getSlotIdActiveCategory(
        route.params.slotId ?? initSlotId,
        collectionData.ads as Showroom[]
      );
      setActiveCategory(activeCategoryData);
    }
  }, [collectionData, initSlotId, route.params.slotId]);

  return { onToggleCategory, activeCategory };
};

export const useItem = (adsData: IAdDataBase | null | undefined, route: Route<typeof routes.collection>) => {
  return useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const { slotid } = event.currentTarget.dataset;
    routes
      .collection({
        collectionId: route.params.collectionId,
        slotId: slotid,
      })
      .push();

    const hasAllowEffectsSlotId = adsData && slotid ? adsData[slotid].allowEffects?.length > 0 : false;
    window.location.href =
      Cookie.get(FEATURE_FLAG_DMEXCO) && hasAllowEffectsSlotId
        ? `/${slotid}`
        : `${BASE_PREVIEW_URL}/mobile/${slotid}?nomenu`;
  }, [route.params.collectionId, adsData]);
};
