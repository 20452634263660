import React, { PropsWithChildren } from 'react';

import style from './styles.module.scss';

export const DesktopFrame = (props: PropsWithChildren) => {
  return (
    <div className={style.frame_wrapper}>
      <div
        className={style.desktop}
        data-testid="desktopFrame"
      >
        <div className={style.inner_frame}>
          <div className={style.holder}>
            <div className={style['iframe-wrapper']}>
              <div className={style.holder}>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
