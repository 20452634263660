import { E_THEME } from '@common/enums';
import { TThemeConfig } from '@common/types';

export const themeConfig:TThemeConfig = {
  [E_THEME.YOC]: {
    title: 'YOC Ads Showcase Platform',
    head:
      <>
        {process.env.NODE_ENV !== 'development' && (
          <>
            <script
              type="text/javascript"
              src="https://cdn.consentmanager.net/delivery/autoblocking/3fac20376a71.js"
              data-cmp-ab="1"
              data-cmp-host="c.delivery.consentmanager.net"
              data-cmp-cdn="cdn.consentmanager.net"
              data-cmp-codesrc="1"
            />
            <script>window.cmp_block_unknown = false;</script>
          </>
        )}
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="mask-icon" href="/favicon.svg" color="#1d223e" />
        <link rel="shortcut" type="image/x-icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      </>,
  },
  [E_THEME.NEUTRAL]: {
    title: 'Ads Showcase Platform',
    head:
      <>
        {process.env.NODE_ENV !== 'development' && (
          <script
            type="text/javascript"
            async
            src={process.env.PUBLIC_URL + '/cmp-quantcast.js'}
          />
        )}
        <link
          rel="icon"
          type="image/x-icon"
          href="data:image/x-icon;base64,AAABAAEAEBAQAAAAAAAoAQAAFgAAACgAAAAQAAAAIAAAAAEABAAAAAAAgAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAsC8qAP+EAACzh1cAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAACAAAAAAAAACAAAAAAAAEiAAAAADAAAiAAAAAAMzAiAAAAAAAAMzAAAAAAAAAiMzMAAAAAAAADAzAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD//wAA//8AAP//AAD//wAA//8AAP//AAD//wAA//8AAP//AAD//wAA//8AAP//AAD//wAA//8AAP//AAD//wAA" // eslint-disable-line
        />
      </>,
  },
};
