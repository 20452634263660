import { ShowroomSidebarGroupedAd } from '@components/ShowroomSidebarGroupedAd';
import { IShowroomPageProps } from '@pages/Showroom/types';

import { useCategory, useGroupedAds, useItem } from './hooks';

import style from './styles.module.scss';

const ShowroomPageMobile = ({ route, initSlotId, collectionData, adsData }: IShowroomPageProps) => {
  const groupedAds = useGroupedAds(collectionData);
  const { onToggleCategory, activeCategory } = useCategory(initSlotId, collectionData, route);
  const onItemClick = useItem(adsData, route);

  if (!collectionData) {
    return <p>No collection found</p>;
  }

  return (
    <div
      className={style.sidebar}
      data-testid="showroomCollectionContainer"
    >
      {groupedAds.map((group) => {
        return (
          <ShowroomSidebarGroupedAd
            key={group.category}
            group={group}
            onAdSelect={onItemClick}
            activeSlotId={route.params.slotId}
            onToggleCategory={onToggleCategory}
            isExpanded={group.category === activeCategory}
            mobileVersion
          />
        );
      })}
    </div>
  );
};

export default ShowroomPageMobile;
