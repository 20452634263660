import React, { PropsWithChildren } from 'react';

import style from './styles.module.scss';

export const MobileFrame = (props: PropsWithChildren) => {
  return (
    <div className={style.frame_wrapper}>
      <div
        className={style.mobile}
        data-testid="mobileFrame"
      >
        <div className={style.left_buttons}></div>
        <div className={style.inner_frame}>
          <div className={style.holder}>
            <div className={style['iframe-wrapper']}>
              <div className={style.holder}>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
