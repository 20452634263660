/* eslint-disable max-lines */

import { E_AD_CATEGORY_NAME } from '@common/enums';
import { Showroom } from '@common/types';

export const ADS_COLLECTION_DE: Showroom[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_AD,
    slotId: 'Ex1h-nAys-jOrF',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/LAVAZZA-BANNER.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/lavazza-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_AD,
    slotId: 'ti2S-Wki0-eUSN',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/VAILLANT.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/VAILLANT.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '3lfa-iZ0l-Vre5',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/SONIC.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/PARAMOUNT.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'dZ6U-Ol8B-aUMH',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/NESPRESSO-VERTUO-POP.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '3rLY-fFlx-7vP7',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/STUDIOCANAL-DGG.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/STUDIOCANAL.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'L9n1-hnUl-UZyr',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/6-KFC.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/KFC.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'FtCF-fHzE-Lvwt',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/7-RTL-Bachelor.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/RTL.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'y3fW-VJ9i-ZMlx',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/8-Tchibo-Aktionstarif.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/TCHIBO.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'o6DB-OxSe-lxKW',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/STUDIOCANAL-GPM.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/STUDIOCANAL.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'ULXc-Jc4j-CLd6',
    title: 'Incoming Call',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/LEGO.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/LEGO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'MIqS-1seK-IuTP',
    title: 'Wipe Away',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/DISNEY-NUSSKNACKER.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/DISNEY.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'IvGC-VAnD-mtP2',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/12-Panerai-Radiomir.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/PANEREI.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'OpC6-FTR9-jow1',
    title: 'Hot Spots',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/VW-ID5.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/VW.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'dksl-wo5A-Ekf5',
    title: 'Match',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/SKY-VALENTINES.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/SKY.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'iZRt-G4Sr-dqxE',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/NESPRESSO-FESTIVE.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Nrr3-ul2g-zUPk',
    title: 'Swipe',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/KITKAT.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/KITKAT.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '5qjr-cuha-Y2ze',
    title: 'Touch',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/17-MMS-Easter.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/MMS.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'XRDg-Iu7p-AAxs',
    title: 'Slidebar',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/18-Disney-Frozen.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/DISNEY.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'urEc-HfHW-fs7F',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/CINI-MINIS.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/NESTLE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'uQ7m-LLVI-AQvG',
    title: 'Story',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/20-PUIG-CH.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/CARLOLINA-HERRERA.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'aHQ9-8SaZ-HjFG',
    title: 'Shake',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/21-RTL-LOW.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/RTL.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'FE34-wE1b-VfRw',
    title: 'Panorama',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/HASBRO-PEPPA-PIG.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/HASBRO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'fKKp-eDBc-lwHE',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/FUJIFILM.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/FUJIFILM.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'bxwU-dppN-iPY9',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/DEKA.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/DEKA.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'nNw6-UoP0-TXwQ',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/26-Citroen-Aircross.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/CITROEN.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '5Gah-KzMG-b4KI',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/BIG.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/BIG-BOBBYCAR.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'a5lM-II4w-dj7s',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/28-AMEX.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/AMEX.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'e11D-lgKo-ZXto',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/29-KIRI.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/KIRI.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '3WQh-J3YU-fpmo',
    title: 'Interactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/30-Cyberport-Aplle.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/CYBERPORT.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'EGsu-WREw-xESY',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/ICE.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/ICE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'KKPs-Fx2o-TY6N',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/LADIVA.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/DONNA.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'VLkU-ZwNN-cELZ',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/LANCOME.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/LANCOME.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '2Aqf-9gXb-OhIz',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/MBC-EQS-SUV.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/MBC.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'poHn-jr9s-aRto',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/PLAYSTATION.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/PLAYSTATION.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'myg4-MpAR-l9AH',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/NIPPON.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/HOSTA.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'KGf8-NhhQ-NQHw',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/PANDORA.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/PANDORA.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'l5kR-jrIF-p4nD',
    title: 'Static',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/VALENTINO.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/VALENTINO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'Amyc-zdW5-W5VN',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/DISNEY-LUCA.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/DISNEY.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '9nYk-fR68-DCML',
    title: 'Mouse Over',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/48-RTL-PLL.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/RTL.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '8o0s-1Ubf-obr2',
    title: 'Reactive Scroll',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/49-MarcoPolo.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/MARCOPOLO.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'zdgB-Iry1-FOcR',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/HENDRICKS.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/HENDRICKS.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'tvXS-wwmq-o5UA',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/51-RTL-Bachelor.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/RTL.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ulJs-d60w-fjnQ',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/52-Nintendo-Zelda.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/NINTENDO.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'Au5x-A4I8-Kzn7',
    title: 'Interactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/NESPRESSO-VERTUO-POP.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'Qrld-5VtU-a6Fn',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/54-Nintendo-Zelda.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/NINTENDO.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '6xbn-hxXC-4cla',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/HENDRICKS.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/HENDRICKS.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ANrn-M5VY-J45P',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/56-Kia-EV6.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/KIA.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'oTq3-qBnj-mcFb',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/57-WOW-AJLT.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/WOW.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '8YMz-T9xt-wHu2',
    title: 'Interactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/58-Shark-Flexstyle.jpg',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/SHARK.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'udnA-v9eH-hSuG',
    title: 'Interactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/NESPRESSO-VERTUO-POP.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'Ew4G-y5l0-DD4S',
    title: 'Interactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/ICE.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/ICE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'PWlB-eyua-ds25',
    title: 'Interactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/XIAOMI.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/XIAOMI.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'YnZj-5lCG-6nXb',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/BALVENIE.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/BALVENIE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'jHTB-Ht8p-vQlq',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/SPARKASSE.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/SPARKASSE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: '2WEq-tNsk-r8VQ',
    title: 'Inline to Sticky',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/background/AMAZON.png',
    logoUrl: 'https://cdn.yoc.com/DE/showroom/DE/compressed/logo/AMAZON-PRIME-VIDEO.svg',
  },
];
