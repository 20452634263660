import React, { FunctionComponent, useState } from 'react';

import { DesktopFrame } from '@components/DesktopFrame';
import { Iframe } from '@components/Iframe';
import { MobileFrame } from '@components/MobileFrame';

import { IPreviewScreenProps } from './types';

import style from './styles.module.scss';

export const PreviewScreen: FunctionComponent<IPreviewScreenProps> = ({ mode, url, iframeRef /* theme */ }) => {
  const [isError, setIsError] = useState(false);

  const Frame = mode === 'desktop' ? DesktopFrame : MobileFrame;

  return (
    <>
      <Frame>
        {isError ? (
          <div className={style.overlay}>
            <p data-testid="error">
              Error loading content <br />
              Please refresh the page
            </p>
          </div>
        ) : (
          <Iframe
            key={url}
            src={url}
            ref={iframeRef}
            onError={() => {
              setIsError(true);
            }}
            className={style.iframe}
            allowFullScreen
            allow="geolocation"
            data-testid="previewIframe"
            sandbox="allow-same-origin allow-forms allow-scripts allow-popups"
          />
        )}
      </Frame>
    </>
  );
};
