import React from 'react';
import { Route } from 'type-route';

import { HelmetHead } from '@components/Head';
import { routes } from '@navigation/router';

import { useCookiePage } from './hooks';

import styles from '@components/Preview/styles.module.scss';

const CookiePage = ({ route }: { route: Route<typeof routes.cookie> }) => {
  const { message } = useCookiePage({ actionType: route.params.actionType });

  return (
    <>
      <HelmetHead />

      <div
        className={styles.content}
        style={{ textAlign: 'center' }}
      >
        {message}
      </div>
    </>
  );
};
export default CookiePage;
