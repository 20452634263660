import { E_AD_CATEGORY_NAME } from '@common/enums';
import { ADS_COLLECTION } from '@configs/index';
import { TAdCategoryCode } from '@utils/location/constants';

export const getDefaultDisplayedAd = (collectionId: TAdCategoryCode) => {
  const adCollection = ADS_COLLECTION[collectionId];
  // Setting default expanded ad category to be
  // YOC Branded Takeover if there is no defaultCategory specified
  const defaultAd = adCollection.ads.find((item) => {
    return item?.category === (adCollection?.defaultCategory ?? E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER);
  });
  return defaultAd?.slotId;
};
