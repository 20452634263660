/* eslint-disable max-lines */

import { E_AD_CATEGORY_NAME } from '@common/enums';
import { Showroom } from '@common/types';

export const ADS_COLLECTION_AT: Showroom[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'zW1G-NdNc-Flrj',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_tchibo.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-tchibo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'NEKT-MDIm-N6Pu',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_wilderkaiser.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-wilderkaiser.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'Ocjf-9yof-AVug',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/AT_UVA_Background_Samsung.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/samsung-logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: '7anG-FcGu-jCbu',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/AT_UVA_Background_Land_Rover.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/landrover-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'aTp7-fK9e-vdtF',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_unicredit.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-unicrediz.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '3ydm-9UQn-zB30',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_pomito.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-pomito.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'epbZ-IjZY-j8fM',
    title: 'Wipe Away',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_tonis.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/tonis-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'ifNs-s78F-HLON',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/hofstaedter-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/hofstaedter-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '3BOa-QQ3z-1vk9',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/milka-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Milka_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'bZIB-2HQ5-HUfg',
    title: 'Slider Bar',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg-mcdonalds-spicychicken.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/McD_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'wBG1-IbER-2KbW',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/audible-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/audible-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'v6WY-IYHh-KIo6',
    title: 'Dynamic Battery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/volvo-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/volvo_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'pq7x-8FhW-I7wa',
    title: 'Map Integration',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/subway-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/subway_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'w1TJ-pDxt-F4Up',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_2min2mio.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/puls4-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '64Ou-tDTZ-M9Fx',
    title: 'Scroll-Reactive + Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_skoda.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/skoda-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'fbdm-zRYN-Tcvc',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/volvo-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/volvo_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'RLYw-JnJJ-jKuk',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_kellys.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/kellys-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'osJ0-Lpqi-5D9L',
    title: 'Scroll-Reactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg_kellys.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/kellys-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'GTn7-z9Qo-bN67',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg-bionorica.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-bionorica.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: '7R79-afk0-0YFb',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/Video1_Samsung.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/samsung_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'BdyT-wSuL-BAUF',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/ladiva-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/donna-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'dmy5-oG8P-PBiL',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/sport2000-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/sport2000-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'FFAR-teu6-y7r6',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/juwelierwagner-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/juwelierwagner-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'WRwl-mZV2-ESeQ',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/win2day-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/win2day-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'iLV5-T54k-VagN',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/lidl-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/lidl-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '6K7o-jFQe-xfDP',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/BrandedTakeover2_Allianz.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Allianz_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '6i8I-lbaZ-eEun',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/BrandedTakeover3_Purina.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/purina_felix_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '34Po-xrAJ-2pVM',
    title: 'Quiz',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bmw-bg,png.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/bmw-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'uXoA-X4Jr-EJ4Q',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '3GrG-pQ63-Bi93',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/nivea1-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/nivea-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'a0PA-9ECY-0Cvc',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/mcdonalds-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/mcd-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'gYOc-zdVy-Bbf0',
    title: 'Folder',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/Folder1_Interspar.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/spar_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'q8Pt-p0IQ-VjLg',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/personalshop-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/personalshop-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'htG5-dMeF-4k8v',
    title: 'Scroll-Reactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/Skin1-2_Mercedes.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Mercedes_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'Ce2c-VE5x-1Vkt',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/Skin1-2_Mercedes.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Mercedes_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'MAI5-Ro10-pP0u',
    title: 'Scroll-Reative',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg-dakommichhher.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-dakommichher.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'MS1m-7SP7-dFij',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg-dakommichhher.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-dakommichher.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'y9c8-Aih7-kr37',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/Skin9_UPI.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/UPI_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'j4d4-chMi-Z3dA',
    title: 'Scroll-Reactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg-abarth.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/logo-abarth.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ZRth-BQyI-2IaJ',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/bg-hornbach.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/hornbach-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'B4Bm-TctZ-109K',
    title: 'Scroll-Reactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/lillet-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/lillet-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '5qwm-6Jme-I0lQ',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/lillet-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/lillet-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'PAL3-CEok-SEa9',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/volvo-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/volvo_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'xhCH-3xgX-TnKQ',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/Sitebar1_Magenta.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Magenta_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'KG9D-iuyS-rauZ',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/Video1_Samsung.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/samsung_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'RwBo-JIrP-8QRc',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/milka-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Milka_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'O6ae-WIHX-u1WW',
    title: 'Mosaic',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/viennahouse-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/viennahouse-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'bJ8O-W0Rp-mpdq',
    title: 'Interaction',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/refurbed-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/refurbed-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'a8QT-Ygf0-0kny',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/disney-bg-png.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Disney_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'GeDQ-mH1H-qQYn',
    title: 'Branded Player',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/AT/Background/nivea2-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/nivea-logo.png',
  },
];
