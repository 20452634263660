import { FunctionComponent, useCallback } from 'react';

import PreviewButton from '@components/PreviewButton';

import { IPreviewSwitchProps } from './types';

import style from './styles.module.scss';

const PreviewSwitch: FunctionComponent<IPreviewSwitchProps> = ({
  mode,
  setMode,
  roundBorders = true,
  disabled = false,
  fullscreenPreviewUrl = '',
  isModeSwitchAllowed,
}) => {
  const switchToMobile = useCallback(() => {
    if (!disabled) {
      setMode('mobile');
    }
  }, [setMode, disabled]);

  const switchToDesktop = useCallback(() => {
    if (!disabled) {
      setMode('desktop');
    }
  }, [setMode, disabled]);

  return (
    <div
      className={style.switch}
      data-testid="previewSwitch"
    >
      {isModeSwitchAllowed && (
        <>
          <PreviewButton
            data-testid="mobileButton"
            text="Mobile"
            previewClass="mobile"
            onClick={switchToMobile}
            mode={mode}
            roundBorders={roundBorders}
            disabled={mode !== 'mobile' && disabled}
            disabledTitle={'The mobile preview is not available. This product is for desktop only.'}
          />
          <PreviewButton
            text="Desktop"
            previewClass="desktop"
            onClick={switchToDesktop}
            mode={mode}
            data-testid="desktopButton"
            roundBorders={roundBorders}
            disabled={mode !== 'desktop' && disabled}
            disabledTitle={'The desktop preview is not available. This product is for mobile only.'}
          />
        </>
      )}
      {mode === 'desktop' && (
        <PreviewButton
          text="Fullscreen"
          previewClass="fullscreen"
          onClick={(event) => {
            event.preventDefault();
            window.open(fullscreenPreviewUrl, '_blank');
          }}
          data-testid="fullscreenButton"
          roundBorders
          disabled={false}
          disabledTitle={''}
          mode={mode}
        />
      )}
    </div>
  );
};

export default PreviewSwitch;
