import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Route } from 'type-route';

import { IAdDataBase, IShowcaseCollection, IShowroomCollection } from '@common/types';
import { HelmetHead } from '@components/Head';
import { Header } from '@components/Header';
import { ADS_COLLECTION } from '@configs/index';
import { routes, useRoute } from '@navigation/router';
import ShowroomPage from '@pages/ShowroomPage';
import ShowroomPageMobile from '@pages/ShowroomPageMobile';
import { fetchAdData } from '@utils/index';
import { E_COUNTRY_CODE, E_CUSTOM_CODE } from '@utils/location/constants';

import { getDefaultDisplayedAd } from './helpers';

export const Showroom = () => {
  const route = useRoute() as Route<typeof routes.collection>;
  const [adsData, setAdsData] = useState<IAdDataBase | null>(null);

  const collectionId: E_COUNTRY_CODE | E_CUSTOM_CODE =
    (route.params.collectionId as E_COUNTRY_CODE) || E_COUNTRY_CODE.GERMANY;
  const collectionData: IShowroomCollection | IShowcaseCollection = ADS_COLLECTION[collectionId];

  const isCollection = ['collection'].includes(collectionData.type);

  let initSlotId = collectionData?.ads[0]?.slotId;
  if (
    collectionData.type === 'showroom-collection' ||
    collectionData.type === 'collection-extended' ||
    collectionData.type === 'collection'
  ) {
    const defaultAdSlotId = getDefaultDisplayedAd(collectionId);
    // reassign an `initSlotId` to first one from specific category only for Showroom
    initSlotId = defaultAdSlotId || initSlotId;
  }

  useEffect(() => {
    const getAdData = async () => {
      const slotIds = collectionData?.ads.map((item) => {
        return item.slotId;
      });

      const data = await fetchAdData(slotIds);

      setAdsData(data);
    };

    if (collectionData) {
      void getAdData();
    }
  }, [collectionData]);

  useEffect(() => {
    if (collectionId && initSlotId) {
      // add `initSlotId` to URL if:
      // 1. there is no `slotId` at all
      // 2. provided `slotId` is not exist in current Ad Collection
      if (
        !route.params.slotId ||
        !collectionData.ads.some((ad) => {
          return ad.slotId === route.params.slotId;
        })
      ) {
        routes
          .collection({
            collectionId,
            slotId: initSlotId,
          })
          .replace();
      }
    }
  }, [collectionId, initSlotId, collectionData.ads, route.params.slotId]);

  if (isMobile) {
    return (
      <>
        <HelmetHead />
        <Header
          tag={collectionData.tag}
          navigation={isCollection ? false : undefined}
        />
        <ShowroomPageMobile
          route={route}
          initSlotId={initSlotId}
          collectionData={collectionData}
          adsData={adsData}
        />
      </>
    );
  }

  return (
    <>
      <HelmetHead />
      <Header
        tag={collectionData.tag}
        navigation={isCollection ? false : undefined}
      />
      <ShowroomPage
        route={route}
        initSlotId={initSlotId}
        collectionData={collectionData}
        adsData={adsData}
      />
    </>
  );
};

export default Showroom;
