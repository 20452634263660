import React, { FunctionComponent } from 'react';

import { IPublisherSelectProps } from './types';

export const PublisherSelect: FunctionComponent<IPublisherSelectProps> = ({
  value,
  disabled,
  onChange,
  className,
  activeOption,
}) => {
  return (
    <select
      className={className}
      disabled={disabled}
      onChange={onChange}
      defaultValue={activeOption}
    >
      {value.map((ad) => {
        return (
          <option
            key={ad.key}
            value={ad.key}
          >
            {ad.title ?? ad.key}
          </option>
        );
      })}
    </select>
  );
};
