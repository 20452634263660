import { createRouter, defineRoute, noMatch, param, ValueSerializer } from 'type-route';

import { E_COOKIE_ACTION_TYPE } from '@pages/CookiePage/types';
import { COLLECTION_ID } from '@utils/location/constants';
import { validateSlotId } from '@utils/validators';

const valueSerialize = (isValidRaw: (raw: string) => Boolean): ValueSerializer<string> => {
  return {
    parse: (raw) => {
      if (!isValidRaw(raw)) {
        return noMatch;
      }

      return raw;
    },
    stringify: (value) => {
      return value;
    },
  };
};

const collectionIdType = valueSerialize((raw) => {
  return (COLLECTION_ID as string[]).includes(raw);
});

const slotIdType = valueSerialize((raw) => {
  return validateSlotId(raw);
});

const cookieType = valueSerialize((raw) => {
  return (Object.values(E_COOKIE_ACTION_TYPE) as string[]).includes(raw);
});

export const { RouteProvider, useRoute, routes } = createRouter({
  home: defineRoute('/'),
  collection: defineRoute(
    {
      collectionId: param.path.ofType(collectionIdType),
      slotId: param.path.optional.string,
    },
    (p) => {
      return `/${p.collectionId}/${p.slotId}`;
    }
  ),
  preview: defineRoute(
    {
      slotId: param.path.ofType(slotIdType),
      mode: param.path.optional.string,
      product: param.query.optional.string,
      effect: param.query.optional.string,
    },
    (p) => {
      return `/${p.slotId}/${p.mode}`;
    }
  ),
  cookie: defineRoute(
    {
      actionType: param.path.ofType(cookieType),
    },
    (p) => {
      return `/cookie/${p.actionType}`;
    }
  ),
});
