import axios from 'axios';

import { ADS_HOST_PRODUCTION, ADS_HOST_STAGING } from '@common/configs';

export const fetchAdData = async (slotId: string[]) => {
  // get data from production host
  const { data } = await axios.get(`//${ADS_HOST_PRODUCTION}/a/ad-preview-type`, {
    params: { slotId },
  });

  if (process.env.ADS_ENVIRONMENT !== 'production') {
    // check if we have a slotIds with null value (not found)
    const nullSlotIds = Object.keys(data).filter((item) => {
      return data[item] === null;
    });

    if (nullSlotIds.length === 0) {
      return data;
    }

    // search slotIds in staging DB
    const { data: dataStaging } = await axios.get(`//${ADS_HOST_STAGING}/a/ad-preview-type`, {
      params: { slotId: nullSlotIds },
    });

    return { ...data, ...dataStaging };
  }

  return data;
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

// This is used to name screenshotted images.
// It returns the following date - time format -> YYYY-MM-DD_HH-MM
export const formatDate = (date: Date) => {
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
    '_' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join('-')
  );
};
