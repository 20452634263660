import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet-async';
import { themeConfig } from 'src/themes/config';

import { E_THEME } from '@common/enums';

export const HelmetHead: FunctionComponent<{ theme?: E_THEME }> = (props) => {
  const { theme = E_THEME.YOC } = props;
  return (
    <Helmet>
      {themeConfig[theme].head}
      <title>{themeConfig[theme].title}</title>
    </Helmet>
  );
};
