/**
 * Hook for working with cookies based on URL parameters.
 * Type of operation (ADD, REMOVE, CHECK).
 * Example of adding cookies: /cookie/add?isTestCookie=true&theme=default
 * Example of removing cookies: /cookie/remove?isTestCookie&theme
 * Example of checking cookies: /cookie/check?isTestCookie
 */

import { useEffect, useState } from 'react';
import Cookie from 'js-cookie';

import { COOKIE_EXPIRE_DAY } from './constants';
import { E_COOKIE_ACTION_TYPE } from './types';

export const useCookiePage = ({ actionType }: { actionType: string }) => {
  const [queryParams] = useState<URLSearchParams>(new URLSearchParams(window.location.search));
  const [message, setMessage] = useState<string>('');
  const queryParamsLength = Array.from(queryParams).length;

  useEffect(() => {
    switch (actionType) {
      case E_COOKIE_ACTION_TYPE.ADD:
        if (queryParamsLength > 0) {
          queryParams.forEach((value, key) => {
            Cookie.set(key, value, {
              expires: COOKIE_EXPIRE_DAY,
              secure: true,
              sameSite: 'strict',
              path: '/',
            });
          });
        } else {
          // TODO: Temporary solution for old flow of Screenshot Tool
          Cookie.set('_yss', 'true', {
            expires: COOKIE_EXPIRE_DAY,
            secure: true,
            sameSite: 'strict',
            path: '/',
          });
        }

        setMessage('The cookie was set successfully!');
        break;

      case E_COOKIE_ACTION_TYPE.REMOVE:
        if (queryParamsLength > 0) {
          queryParams.forEach((_, key) => {
            Cookie.remove(key);
          });

          setMessage('The cookie has been successfully deleted!');
        }
        break;

      case E_COOKIE_ACTION_TYPE.CHECK:
        if (queryParamsLength > 0) {
          let checkResult = '';
          queryParams.forEach((_, key) => {
            if (key) {
              const cookieValue = Cookie.get(key);
              if (cookieValue) {
                checkResult += `Cookie value for key "${key}": "${cookieValue}". `;
              } else {
                checkResult += `Cookie with key "${key}" not found. `;
              }
            } else {
              setMessage('Please provide a key for cookie check.');
            }
          });

          setMessage(checkResult);
        }

        break;

      default:
        break;
    }

    if (queryParamsLength === 0 && actionType !== E_COOKIE_ACTION_TYPE.ADD) {
      setMessage('Empty URL query. Please add value.');
    }
  }, [actionType, queryParams, queryParamsLength]);

  return { message };
};
