import React, { FunctionComponent, useRef } from 'react';
import cn from 'classnames';
import { QRCode } from 'yoc-ui-library';

import { E_THEME } from '@common/enums';
import { Carousel } from '@components/GalleryCarousel';
import PreviewButton from '@components/PreviewButton';
import { PreviewScreen } from '@components/PreviewScreen';
import PreviewSwitch from '@components/PreviewSwitch';
import { PublisherSelect } from '@components/PublisherSelect';
import { slotId2URL } from '@pages/PreviewPageSlotId/helpers';

import { buildPreviewUrl, filterPublisherList, isCookieSet } from './helpers';
import { usePreview, usePreviewGallery, useScreenshot } from './hooks';
import { IPreviewProps } from './types';

import style from './styles.module.scss';

export const Preview: FunctionComponent<IPreviewProps> = ({
  slotId,
  initialMode,
  isModeSwitchAllowed,
  isShowroom = false,
  enableScreenshot = false,
  theme = E_THEME.YOC,
  product,
  effect,
  adData,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { mode, setMode } = usePreview(slotId, initialMode, isShowroom, isCookieSet && enableScreenshot, effect);
  const publisherList = filterPublisherList(mode);
  const qrUrl = slotId2URL({
    slotId,
    previewMode: mode,
    isScreenshotEnabled: isCookieSet && enableScreenshot,
    publisherKey: publisherList[0].key,
    theme,
  });

  const {
    isCarouselMode,
    isCarouselVerticalMode,
    onGalleryChange,
    gallerySlides,
    hasGallerySlidePreviewSwitch,
    hasGallerySlideQrCode,
    currentEffect,
    isDMEXCOMode,
  } = usePreviewGallery({
    mode,
    adData,
    isShowroom,
    qrUrl,
    iframeRef,
    effect,
  });

  const {
    delay,
    disabledTitleDownload,
    isDownloadStarted,
    isIframeLoaded,
    onDelayChange,
    onPublisherSelect,
    onScreenshot,
  } = useScreenshot(slotId, mode, enableScreenshot, publisherList, product, iframeRef);

  return (
    <div
      className={style.container}
      data-testid="preview"
    >
      {isDMEXCOMode && isCarouselMode && (
        <header className={style['gallery-header']}>
          <h1>YOC Universal video ad</h1>
          <p>One asset. Various formats.</p>
        </header>
      )}

      {isCarouselMode ? (
        <span key={slotId}>
          <Carousel
            slides={gallerySlides}
            onChange={onGalleryChange}
            isVerticalMode={isCarouselVerticalMode || isDMEXCOMode}
          />
        </span>
      ) : (
        <div className={style.content}>
          <PreviewScreen
            mode={mode}
            url={buildPreviewUrl({ qrUrl, isCarouselMode, currentEffect, useForPreview: true, product })}
            iframeRef={iframeRef}
            theme={theme}
          />

          {enableScreenshot && isCookieSet && (
            <div
              className={style.screenshot}
              data-testid="screenshotControls"
            >
              <PublisherSelect
                className={style.select}
                disabled={!isIframeLoaded || isDownloadStarted}
                onChange={onPublisherSelect}
                value={publisherList}
              />
              <div
                className={cn(style.delayContainer, {
                  [style.disabled]: !isIframeLoaded || isDownloadStarted,
                })}
              >
                Delay of
                <input
                  className={style.delay}
                  disabled={!isIframeLoaded || isDownloadStarted}
                  value={delay}
                  onChange={onDelayChange}
                  type="number"
                  name="delay"
                  min="100"
                />
                ms
              </div>
              <PreviewButton
                text="Download Screenshot"
                onClick={onScreenshot}
                previewClass="screenshot"
                data-testid="screenshotButton"
                roundBorders
                disabled={!isIframeLoaded || isDownloadStarted}
                disabledTitle={disabledTitleDownload}
                mode={mode}
              />
            </div>
          )}
        </div>
      )}

      {isDMEXCOMode && isCarouselMode ? null : (
        <>
          <PreviewSwitch
            mode={isCarouselMode && hasGallerySlidePreviewSwitch ? 'desktop' : mode}
            setMode={setMode}
            fullscreenPreviewUrl={buildPreviewUrl({
              qrUrl,
              isCarouselMode,
              currentEffect,
              isFullscreenUrl: true,
              product,
            })}
            data-testid="previewSwitch"
            isModeSwitchAllowed={isCarouselMode ? false : isModeSwitchAllowed}
          />
          {((!isCarouselMode && mode !== 'desktop') || hasGallerySlideQrCode) && !isCarouselVerticalMode && (
            <a
              className={style.qr}
              target="_blank"
              rel="noreferrer"
              href={buildPreviewUrl({ qrUrl, isCarouselMode, currentEffect, product })}
              data-testid="qrTestId"
            >
              <QRCode
                size={90}
                value={buildPreviewUrl({ qrUrl, isCarouselMode, currentEffect, product })}
              />
            </a>
          )}
        </>
      )}
    </div>
  );
};
