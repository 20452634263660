import { RefObject } from 'react';

import { E_EFFECT, E_THEME } from '@common/enums';
import { IAdData } from '@common/types';

export type TDisplayMode = 'mobile' | 'desktop';

export interface IPreviewProps {
  slotId: string;
  initialMode: TDisplayMode;
  isModeSwitchAllowed: boolean;
  displayHeader?: boolean;
  isShowroom?: boolean;
  product?: string;
  enableScreenshot?: boolean;
  theme?: E_THEME;
  effect?: E_EFFECT;
  adData?: IAdData | null;
}

export enum E_SCREENSHOT_INFO_MESSAGE {
  WAITING = 'Waiting for the publisher website to arrive',
  DOWNLOADING = 'Downloading screenshot please wait...',
}

export interface IUseGalleryMode {
  adData?: IAdData | null;
  effect?: E_EFFECT;
  isShowroom?: boolean;
  qrUrl: string;
  iframeRef: RefObject<HTMLIFrameElement>;
  mode: TDisplayMode;
}

export interface IGetGalleryPreviewUrl {
  url: string;
  isDMEXCOMode: boolean;
  currentEffect?: E_EFFECT;
  isMobile?: boolean;
  effect?: E_EFFECT;
  product?: IAdData['productKey'];
}
