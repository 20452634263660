import React, { FunctionComponent } from 'react';
import { Route } from 'type-route';

import { routes, useRoute } from '@navigation/router';
import { AVAILABLE_COUNTRIES } from '@utils/location/constants';

import style from './styles.module.scss';

export const Navigation: FunctionComponent = () => {
  const route = useRoute();

  return (
    <ul
      className={style.nav}
      data-testid="navigation"
    >
      {AVAILABLE_COUNTRIES.map((countryId, id) => {
        return (
          <li key={id}>
            <a
              className={
                countryId.toLowerCase() === (route as Route<typeof routes.collection>).params.collectionId
                  ? style.active
                  : undefined
              }
              {...routes.collection({ collectionId: countryId }).link}
            >
              {countryId}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
