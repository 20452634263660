import { E_AD_CATEGORY_NAME } from '@common/enums';
import { Showroom } from '@common/types';

export const ADS_COLLECTION_CH: Showroom[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'bRgX-cnLe-EZu4',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/BMW_YUVA_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BMW_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'R52I-8RXE-aGkk',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Nissan_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Nissan_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'a2iD-O36v-OD5c',
    title: 'Hotspot',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/AUDI_Hotspot_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/AUDI.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'bTQb-L2IB-Ry6p',
    title: 'Tune Ad',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Berocca_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Logo_Berocca.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Aox6-hNU5-8zPL',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Marionnaud_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Logo_Marionnaud.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'gzsg-AAzz-ppbn',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Breuninger_BG_Tap.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BREUNINGER.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'VMpN-ijDZ-piVH',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Breuninger_BG_Carousel_V2.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BREUNINGER.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Pdyb-hNa2-97p5',
    title: 'Drag & Drop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/MALFY_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Malfy_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'xaun-y4v2-5k3y',
    title: 'Swipe',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Coca_Cola_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Coca_Cola_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'XkVR-BPJd-u0sF',
    title: 'Scroll-Reactive / Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/BMW_M2_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BMW_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'aUN3-j4A0-LbZj',
    title: 'Scroll-Reactive / Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/McCrispy_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/McDonald%27s_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '6LFo-HhLe-ZFKG',
    title: 'Scroll-Reactive / Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/McCrispy_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/McDonald%27s_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'pEF5-blXG-qgVW',
    title: 'Video / Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/AUDI_RS_GT.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/AUDI.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'zWIi-9mVv-qmF5',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Breuninger_BG_Spring.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BREUNINGER.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: '7R79-afk0-0YFb',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Samsung_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Samsung_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'nSZo-5B9v-T2WX',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Rivella_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Logo_Rivella.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'z4EA-Q4Gi-FaoL',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Club_Med_BG_Poll.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/logo_Club_Med_V2.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'psbw-rekF-vh89',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/BMW_M2_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BMW_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'p6T4-5kLi-Zasb',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Celebrate_Our_Suppliers_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/McDonald%27s_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'pVp1-xIhP-IIkg',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Royal_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/McDonald%27s_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'em5a-Rs8c-GkiK',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Dyson_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Dyson_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 've22-XFaY-0kzY',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Basler_Kantonal_Bank_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Basler_Versicherung_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '9VLR-yzjB-1Zhu',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/ZKB_Vorsorge_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/ZKB_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '0u1E-xMP2-4won',
    title: 'Quiz',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/VW_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/VW.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '07dU-N0qa-fny3',
    title: 'Video Integration',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/AUDI_RS_GT.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/AUDI.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'PB0q-MC5x-mxNV',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Berocca_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Logo_Berocca.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '70KB-ktZL-BawR',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/SKODA_Brand_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Skoda_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'hHjn-xBbt-S4ZU',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/SKODA_Brand_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Skoda_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'QjL8-yzGt-tglb',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Mercedes_Desire_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Mercedes_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'f8W7-Hh2C-DCkh',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/EMMI_Wendy_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/EMMI_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '5eeq-gZbm-VtFu',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/EMMI_Wendy_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/EMMI_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ADnz-YDgE-qOoz',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Club_Med.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/logo-Club-Med.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'I2da-n8nB-6nwh',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Club_Med.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/logo-Club-Med.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: '0Yip-1hp7-s0PR',
    title: 'Scroll-Reactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/ZKB_BG_ESG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/ZKB_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'Bzjm-Xj53-t34K',
    title: 'Cube',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Germany_Travel_BG_2.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Germany_Travel_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'pLlw-09sW-zBJX',
    title: 'Quiz',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/VW_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/VW.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'XtlM-NLZP-CVBj',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/ZKB_BG_V2.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/ZKB_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: '1ShY-p15L-ttzP',
    title: 'Video Integration',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/AUDI_RS_GT.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/AUDI.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: '5O8c-LoKh-nEfz',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Tipp_Kick_Mania.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/logo_migros-1.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'royV-nLHY-BFzy',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/SKODA_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Skoda_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'JfzD-jEe9-Aka2',
    title: 'Branded Player',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Germany_Travel_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Germany_Travel_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'qppE-boMa-r5dn',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/EMMI_Caffe_Latte.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/EMMI_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'tQSt-UOwV-Fccl',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/CH/Background/Loki_BG.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/Disney_Logo.svg',
  },
];
