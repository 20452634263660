import React, { FunctionComponent } from 'react';

import { HelmetHead } from '@components/Head';
import { routes } from '@navigation/router';

import { MESSAGE_NO_MATCH } from './constants';
import { INoMatchPageProps } from './types';

import styles from './styles.module.scss';

const NoMatchPage: FunctionComponent<INoMatchPageProps> = ({ message = MESSAGE_NO_MATCH }) => {
  return (
    <>
      <HelmetHead />

      <div
        className={styles.wrapper}
        data-testid="no-match-page"
      >
        <h1>NOT FOUND</h1>
        <p>{message}</p>
        <a {...routes.home().link}>GO HOME</a>
      </div>
    </>
  );
};

export default NoMatchPage;
