export const adjustMobilePreviewViewport = (
  iframeWindow: Window,
  {
    fakeScroll,
    mobileLayout,
    adType,
  }: {
    fakeScroll: boolean;
    mobileLayout: boolean;
    adType: string;
  }
): void => {
  iframeWindow.postMessage({ msg: 'init', fakeScroll, mobileLayout, adType }, '*');
};
