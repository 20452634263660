import { FunctionComponent } from 'react';

import { Navigation } from '@components/Navigation';

import { IHeaderProps } from './types';

import style from './styles.module.scss';

export const Header: FunctionComponent<IHeaderProps> = (props) => {
  const { navigation = <Navigation />, tag = 'Showroom' } = props;
  return (
    <header
      className={style.header}
      data-testid="header"
    >
      <span
        className={style.tag}
        data-testid="tag"
      >
        {tag}
      </span>
      {navigation}
    </header>
  );
};
