import { TimeZone } from '@common/types';

export enum E_COUNTRY_CODE {
  AUSTRIA = 'at',
  GERMANY = 'de',
  SWITZERLAND = 'ch',
  POLAND = 'pl',
  FINLAND = 'fi',
}

export enum E_CUSTOM_CODE {
  HQ = 'hq',
  PHARMA = 'pharma',
  TEST = 'test',
  YZA = 'yza',
  TRAVEL = 'travel',
}
export const COLLECTION_ID = Object.values({
  ...E_COUNTRY_CODE,
  ...E_CUSTOM_CODE,
});

export type TAdCategoryCode = E_COUNTRY_CODE | E_CUSTOM_CODE;

export const AVAILABLE_COUNTRIES = Object.values(E_COUNTRY_CODE);
export const DEFAULT_COUNTRY_CODE = E_COUNTRY_CODE.GERMANY;

export const timeZones: TimeZone[] = [
  {
    timeZone: 'Europe/Vienna',
    code: E_COUNTRY_CODE.AUSTRIA,
  },
  {
    timeZone: 'Europe/Berlin',
    code: E_COUNTRY_CODE.GERMANY,
  },
  {
    timeZone: 'Europe/Busingen',
    code: E_COUNTRY_CODE.GERMANY,
  },
  {
    timeZone: 'Europe/Zurich',
    code: E_COUNTRY_CODE.SWITZERLAND,
  },
  {
    timeZone: 'Europe/Warsaw',
    code: E_COUNTRY_CODE.POLAND,
  },
  {
    timeZone: 'Europe/Helsinki',
    code: E_COUNTRY_CODE.FINLAND,
  },
];
