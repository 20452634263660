import { BASE_HOST_URL, BASE_PREVIEW_URL } from '@common/configs';
import { E_PREVIEW_MODE, E_THEME } from '@common/enums';

import { ISlotId2URL } from './types';

export const slotId2URL = ({
  slotId,
  previewMode,
  isScreenshotEnabled = false,
  publisherKey,
  theme,
}: ISlotId2URL): string => {
  let link;

  const layout = `${theme === E_THEME.NEUTRAL ? 'neutral-' : ''}${
    previewMode === E_PREVIEW_MODE.MOBILE ? 'mobile' : 'desktop-showroom'
  }`;

  link = `${BASE_PREVIEW_URL}/${layout}/${slotId}`;

  if (isScreenshotEnabled) {
    link = `${BASE_HOST_URL}/screenshot/${publisherKey}/${slotId}`;
  }

  return link;
};

// NOTE: the logic to forward automatically to cb.* once the cf.* URL is opened on a mobile device
// https://yocmobile.atlassian.net/browse/TSP-7228
export const redirectForMobile = (slotId: string, theme?: string) => {
  const layout = theme === E_THEME.NEUTRAL ? `${E_THEME.NEUTRAL}-mobile` : 'mobile';
  window.location.href = `${BASE_PREVIEW_URL}/${layout}/${slotId}`;
};
