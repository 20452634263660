import { E_AD_CATEGORY_NAME } from '@common/enums';
import { Showroom } from '@common/types';

export const ADS_COLLECTION_TEST: Showroom[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'JJoA-o3gs-XxOv',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/toffifee-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/toffifee_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'bLFP-XJnM-UdUF',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'O7zf-yWv0-ieL1',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/MysteryScroller1_Muenze.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Muenze_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'fbdm-zRYN-Tcvc',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/volvo-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/volvo_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: '7R79-afk0-0YFb',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Video1_Samsung.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/samsung_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'BdyT-wSuL-BAUF',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/ladiva-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/donna-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'eBpm-R7Fo-WZVI',
    title: 'Quiz',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/BrandedTakeover1_Coke.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/coke_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'WRwl-mZV2-ESeQ',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/win2day-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/win2day-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'uXoA-X4Jr-EJ4Q',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '3GrG-pQ63-Bi93',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/nivea1-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/nivea-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'htG5-dMeF-4k8v',
    title: 'Scroll-Reactive',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin1-2_Mercedes.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Mercedes_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'Ce2c-VE5x-1Vkt',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin1-2_Mercedes.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Mercedes_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'xhCH-3xgX-TnKQ',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Sitebar1_Magenta.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Magenta_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'KG9D-iuyS-rauZ',
    title: 'Video',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Video1_Samsung.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/samsung_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'a8QT-Ygf0-0kny',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/disney-bg-png.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Disney_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'X3dO-zoLQ-SkJd',
    title: 'Branded Player',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Video4_Audi.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Audi_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_AD,
    slotId: 'VzR2-nURL-0Lce',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/refurbed-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/refurbed-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_AD,
    slotId: 'oB4a-hFOo-31hf',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/refurbed-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/refurbed-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_AD,
    slotId: 'guBK-0Zsz-DFok',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/refurbed-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/refurbed-logo.png',
  },
];
