import { ReactElement } from 'react';

import { E_COUNTRY_CODE } from '@utils/location/constants';

import { E_AD_CATEGORY_NAME, E_EFFECT, E_THEME } from './enums';

export interface ICollectionBase<T = Showcase> {
  ads: T[];
  tag?: string;
  defaultCategory?: E_AD_CATEGORY_NAME;
}

export interface IShowcaseCollection extends ICollectionBase {
  type: 'collection';
}

export interface IShowroomCollection extends ICollectionBase<Showroom> {
  type: 'showroom-collection' | 'collection-extended';
}

export type Showcase = {
  slotId: string;
  title: string;
  displayMode: 'mobile' | 'desktop';
  category?: E_AD_CATEGORY_NAME;
};

export type Showroom = Showcase & {
  category: E_AD_CATEGORY_NAME;
  logoUrl?: string;
  backgroundUrl?: string;
};

export type AdsData = Record<string, unknown>;

export type TimeZone = {
  timeZone: string;
  code: E_COUNTRY_CODE;
};

export interface IAdDataBase {
  [key: string]: IAdData;
}

export enum E_AD_PRODUCT_KEY {
  YBC = 'ybc',
  YUA = 'yua',
  YMA = 'yma',
  TPA = 'tpa',
  UAF = 'uaf',
  YS = 'ys',
  YZA = 'yza',
  YIVA = 'yiva',
  YISV = 'yisv',
  YUVS = 'yuvs',
}

export interface IAdData {
  previewType: string; // TODO: "mobile" | "?"
  restrictShowcasePreview: 'mobile-and-desktop' | 'desktop-only' | 'mobile-only';
  previewWebsiteLayout: E_THEME;
  allowEffects: E_EFFECT[];
  productKey: E_AD_PRODUCT_KEY;
}

export type TThemeConfig = {
  [key in E_THEME]: { title: string; head: ReactElement };
};
