export const CAROUSEL_SETTINGS_DEFAULT = {
  className: 'slider variable-width',
  dots: true,
  infinite: false,
  centerMode: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
};

export const CAROUSEL_MIN_HEIGHT = 610;
export const CAROUSEL_VERTICAL_MODE_WIDTH = 768;

// TODO: Temporary feature - https://yocmobile.atlassian.net/browse/DC-328
export const FEATURE_FLAG_DMEXCO = 'dmexco';
