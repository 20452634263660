import React, { CSSProperties, FunctionComponent } from 'react';
import cn from 'classnames';
import Cookie from 'js-cookie';

import { E_AD_CATEGORY_NAME } from '@common/enums';
import { ArrowButton } from '@components/ArrowButton';
import { FEATURE_FLAG_DMEXCO } from '@components/GalleryCarousel/configs';

import { IShowroomSidebarGroupedAdt } from './types';

import style from './styles.module.scss';

export const ShowroomSidebarGroupedAd: FunctionComponent<IShowroomSidebarGroupedAdt> = ({
  group,
  onAdSelect,
  activeSlotId,
  onToggleCategory,
  isExpanded,
  mobileVersion,
}) => {
  const isDesktopCollection = group.ads.every((ad) => {
    return ad.displayMode === 'desktop';
  });
  const groupCategory =
    mobileVersion && group.category === 'YOC Desktop & Mobile Skin' ? 'YOC Mobile Skin' : group.category;

  // Hide UVA from mobile view if DMEXCO cookie is not set.
  if (
    mobileVersion &&
    group.category === E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_AD &&
    !Cookie.get(FEATURE_FLAG_DMEXCO)
  ) {
    return null;
  }

  return (
    <div
      className={cn(style.category, {
        [style.desktopHidden]: mobileVersion && isDesktopCollection,
        [style.mobileCategory]: mobileVersion,
      })}
      onClick={() => {
        onToggleCategory(group.category);
      }}
    >
      {((mobileVersion && !isDesktopCollection) || !mobileVersion) && (
        <div
          className={cn(style.category__header, {
            [style.mobile]: mobileVersion,
            [style.expanded]: isExpanded,
          })}
        >
          {mobileVersion && (
            <ArrowButton
              direction={isExpanded ? 'bottom' : 'right'}
              color="white"
              scale={0.75}
            />
          )}
          <span
            className={cn({
              [style.mobileSpan]: mobileVersion,
            })}
          >
            {groupCategory}
          </span>
        </div>
      )}

      {isExpanded && (
        <ul
          className={style.list}
          data-testid="adList"
        >
          {group.ads.map((ad) => {
            const liStyle: CSSProperties = {};
            if (ad.backgroundUrl) {
              (liStyle as any)['--background-url'] = `url(${ad.backgroundUrl})`;
            }
            if (ad.logoUrl) {
              (liStyle as any)['--logo-url'] = `url(${ad.logoUrl})`;
            }
            if ((mobileVersion && ad.displayMode === 'mobile') || !mobileVersion) {
              return (
                <li
                  key={ad.slotId}
                  className={cn(style.list__item, {
                    [style.active]: activeSlotId === ad.slotId,
                    [style.mobileItem]: mobileVersion,
                  })}
                  data-slotid={ad.slotId}
                  data-testid={ad.slotId}
                  onClick={onAdSelect}
                  style={liStyle}
                >
                  <div
                    className={cn(style['list__item-logo'], {
                      [style['list__item-mobileLogo']]: mobileVersion,
                    })}
                  ></div>
                  <span
                    className={cn(style['list__item-title'], {
                      [style['list__item-mobileTitle']]: mobileVersion,
                    })}
                  >
                    {ad.title}
                  </span>
                </li>
              );
            }
            return undefined;
          })}
        </ul>
      )}
    </div>
  );
};
