import { E_AD_CATEGORY_NAME } from '@common/enums';
import { Showroom } from '@common/types';

export const ADS_COLLECTION_HQ: Showroom[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 't9jD-1y9i-gsNh',
    title: 'Nivea',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/hq/showroom/hq/background/yms-nivea.jpg',
    logoUrl: 'https://cdn.yoc.com/hq/showroom/hq/logo/yms-nivea.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'CLvu-5oLy-LAyi',
    title: 'Peugeot',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/hq/showroom/hq/background/yua-peugeot.jpg',
    logoUrl: 'https://cdn.yoc.com/hq/showroom/hq/logo/yua-peugeot.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'YUKC-cbh9-juFC',
    title: 'Audi',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/hq/showroom/hq/background/yiva-audi.jpg',
    logoUrl: 'https://cdn.yoc.com/hq/showroom/hq/logo/yiva-audi.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'rxyW-3nhQ-5gKL',
    title: 'Lancôme',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/hq/showroom/hq/background/ybt-loreal.jpg',
    logoUrl: 'https://cdn.yoc.com/hq/showroom/hq/logo/ybt_lancome.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'ZyEY-d9Js-cYH9',
    title: 'Merci',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/hq/showroom/hq/background/yma-merci.jpg',
    logoUrl: 'https://cdn.yoc.com/hq/showroom/hq/logo/yma-merci.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_DESKTOP_SKIN,
    slotId: 'n0yn-h9JL-Hd42',
    title: 'Bacardi',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/desktopskin1_bacardi.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/bacardi_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MOBILE_SKIN,
    slotId: '6P6n-oSch-GGuy',
    title: 'Bacardi',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/mobileskin1_bacardi.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/bacardi_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'RT2e-u1g7-gRyA',
    title: 'Hornbach',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/sitebar1_hornbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/hornbach_logo.png',
  },
];
