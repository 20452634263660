import { E_AD_CATEGORY_NAME } from '@common/enums';
import { Showroom } from '@common/types';

export const ADS_COLLECTION_FI: Showroom[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '0Fj7-aPVu-186w',
    title: 'Wipe',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/compressed_Telia.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Djk3-4HB6-uZVY',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/McD_fries.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'NTDy-MHbw-DpJj',
    title: '3D Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/ifolor_fathers_day_mystery_ad.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '3SWx-2ju4-WaYI',
    title: '3D Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/ifolor_christmas_mystery_ad.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'nO8G-Nyrh-C46p',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/hbo_barbie_mystery_ad.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'agMl-jaKz-lGq9',
    title: 'Gyro',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/novart_gyro_1.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'mkj3-sqOW-DdsM',
    title: 'Gyro',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/novart_gyro_2.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'tpnu-npjh-HROJ',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/novart_tap.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'EGsu-WREw-xESY',
    title: 'Video Integration',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/ICE_mysteryscroller.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'FLET-F0X1-LFXk',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Samsung_ZoomAd.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'P3IS-T5TB-Zjyk',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/mtv3_katsomo_branded_takeover.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'Kotf-76Qs-HURZ',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/picadeli_branded_takeover.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'f6Em-FIPE-yQl2',
    title: 'Wipe',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/compressed_Telia.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'mKJ3-Y2f8-U1NA',
    title: 'Standard',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/McD_fries.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'kQXF-EYxk-3o0x',
    title: 'Standard',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/WB_InlineVideo.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'DzTd-qFJI-lxUv',
    title: 'Standard',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Spain_InlineVideo.jpg',
  },
];
