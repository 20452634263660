import { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Cookie from 'js-cookie';
import { Route } from 'type-route';

import { IAdData } from '@common/types';
import { FEATURE_FLAG_DMEXCO } from '@components/GalleryCarousel/configs';
import { TDisplayMode } from '@components/Preview/types';
import { routes, useRoute } from '@navigation/router';
import { fetchAdData } from '@utils/index';

export const useAdPreview = (slotId: string, mode: TDisplayMode) => {
  const previewMode: TDisplayMode = mode === 'desktop' ? 'desktop' : 'mobile';
  const [adsDataCollection, setAdsDataCollection] = useState<IAdData | null>();
  const route = useRoute() as Route<typeof routes.preview>;

  const setMode = useCallback((displayMode: TDisplayMode) => {
    routes.preview({ slotId, mode: displayMode }).replace();
  }, [slotId]);

  useEffect(() => {
    const getAdData = async () => {
      const data = await fetchAdData([slotId]);

      setAdsDataCollection(data[slotId]);
      // previewWebsiteLayout can be null, default or neutral.
      // There is a 'neutral' class at the moment.
      if (data[slotId]?.previewWebsiteLayout) {
        document.body.classList.add(data[slotId].previewWebsiteLayout);
      }
    };

    if (slotId) {
      void getAdData();
    }
  }, [slotId]);

  useEffect(() => {
    if (adsDataCollection) {
      const initialPreviewMode =
        typeof adsDataCollection?.previewType !== 'string'
          ? 'mobile'
          : adsDataCollection.previewType;
      const initialRestrictShowcasePreviewMode =
        typeof adsDataCollection?.restrictShowcasePreview !== 'string'
          ? 'mobile-and-desktop'
          : adsDataCollection.restrictShowcasePreview;

      if (
        adsDataCollection.allowEffects &&
        adsDataCollection?.allowEffects.length > 0
      ) {
        if (route.params.mode) {
          routes.preview({ slotId }).replace();
        }

        return;
      }

      if (
        initialPreviewMode === 'desktop' ||
        initialRestrictShowcasePreviewMode === 'desktop-only'
      ) {
        routes.preview({ slotId, mode: 'desktop' }).replace();
      } else if (
        initialRestrictShowcasePreviewMode === 'mobile-only' &&
        initialPreviewMode !== 'desktop'
      ) {
        routes.preview({ slotId, mode: 'mobile' }).replace();
      }

      if (mode === undefined) {
        routes.preview({ slotId, mode: 'mobile' }).replace();
      }
    }
  }, [slotId, adsDataCollection, mode, route.params.mode]);

  const shouldRedirectForMobile =
    isMobile &&
    (adsDataCollection?.previewType === 'mobile' || (!adsDataCollection && previewMode === 'mobile')) &&
    !Cookie.get(FEATURE_FLAG_DMEXCO); // TODO: Temporary feature https://yocmobile.atlassian.net/browse/DC-328

  return useMemo(() => {
    return {
      adsDataCollection,
      previewMode,
      setMode,
      shouldRedirectForMobile,
    };
  }, [adsDataCollection, previewMode, setMode, shouldRedirectForMobile]);
};
