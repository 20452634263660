export enum E_AD_CATEGORY_NAME {
  YOC_MYSTERY_AD = 'YOC Mystery Ad®',
  YOC_MYSTERY_SCROLLER = 'YOC Mystery Scroller®',
  YOC_ZOOM_AD = 'YOC Zoom Ad',
  YOC_BRANDED_TAKEOVER = 'YOC Branded Takeover',
  YOC_UNDERSTITIAL_AD = 'YOC Understitial Ad®',
  YOC_SKIN = 'YOC Skin',
  YOC_DESKTOP_SKIN = 'YOC Desktop Skin', // TODO: check the final list
  YOC_MOBILE_SKIN = 'YOC Mobile Skin', // TODO: check the final list
  YOC_SITEBAR = 'YOC Sitebar',
  YOC_NONE_IN_SITEBAR = 'YOC None In Sitebar',
  YOC_INLINE_VIDEO_AD = 'YOC Inline Video Ad',
  YOC_UNIVERSAL_VIDEO_AD = 'YOC Universal Video Ad',
  YOC_BRANDED_PLAYER = 'YOC Inline Video Ad Branded Player',
  YOC_INSTREAM_AD = 'YOC Instream Video Ad',
  YOC_UNIVERSAL_VIDEO_SOLUTION = 'YOC Universal Video Solution',
}

export enum E_THEME {
  NEUTRAL = 'neutral',
  YOC = 'default',
}

export enum E_PREVIEW_MODE {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export enum E_EFFECT {
  NONE = 'none',
  INLINE_TO_STICKY = 'inlineToSticky',
  UNDERSTITIAL_MINI = 'understitialMini',
  UNDERSTITIAL_MINI_TO_STICKY = 'understitialMiniToSticky',
  STICKY = 'sticky',
  FULLSCREEN_OVERLAY = 'fullscreenOverlay',
  SITEBAR = 'sitebar',
  NONE_IN_SITEBAR = 'noneInSitebar',
  ZOOM = 'zoom',
  ZOOM_TO_STICKY = 'zoomToSticky',
  BRANDED_PLAYER = 'brandedPlayer',
  NONE_IN_STICKY = 'noneInSticky',
}
