import { useEffect } from 'react';

export const usePostMessageHandler = <T extends unknown>(handler: (event: MessageEvent<T>) => void): void => {
  useEffect(() => {
    window.addEventListener('message', handler, false);

    return () => {
      window.removeEventListener('message', handler, false);
    };
  }, [handler]);
};
