import { E_AD_CATEGORY_NAME } from '@common/enums';
import { Showroom } from '@common/types';

export const ADS_COLLECTION_TRAVEL: Showroom[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'KAvT-YprR-556z',
    title: 'Wipe Away & Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/obertauern-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/obertauern-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Pq9X-jGLE-19CK',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/obertauern-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/obertauern-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'OZuU-MMAC-RyPo',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Folder3_Oebb.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/OEBB_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'vfYC-lRaz-ihJK',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'sXs6-eNnF-DfQC',
    title: 'Dynamic Battery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'pWvz-fikD-tchx',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'qSfo-rrvu-uNlr',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'wYTe-pml6-Iz3K',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Alxf-wY01-YZP3',
    title: 'Wipe Away',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/seefestspiele-moerbisch-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/seefestspiele-moerbisch-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '8OxT-LmPL-oReS',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/emirates-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/emirates-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'jjVf-dpIj-5j1b',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'D4dJ-sQK0-nN0D',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/vrr-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/vrr-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'rEWr-KlE9-QdNQ',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/ruefa_bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/ruefa_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'MYk0-ccNU-tCyU',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/tropicalisland-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/tropicalisland-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: '2Dx0-1lSY-uBvm',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/obertauern-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/obertauern-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'Lzyo-2Wlj-HRru',
    title: 'Mobile | Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/salzburgersportwelt-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/salzburgersportwelt-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '82Kv-2nhl-Ux3w',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/dzt-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/DZT_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'buK1-el9m-QP4B',
    title: 'Dynamic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '23Jr-ZxJI-nw67',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '0zNN-JuQv-tkjN',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'S943-pp9l-kEGb',
    title: 'Wipe Away & Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/schloss-hof-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/schloss-hof-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'oL1c-uqaC-2yJw',
    title: 'Dynamic Battery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '44RB-3gOH-9JOh',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'CUGk-CkK3-oziq',
    title: 'Cube',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/dzt-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/DZT_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '2KZE-iKgM-1Fqo',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/dzt-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/DZT_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ADnz-YDgE-qOoz',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/CH/showroom/ch/compressed/background/Club_Med.png',
    logoUrl: 'https://cdn.yoc.com/CH/showroom/ch/compressed/logo/logo-Club-Med.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'I2da-n8nB-6nwh',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/CH/showroom/ch/compressed/background/Club_Med.png',
    logoUrl: 'https://cdn.yoc.com/CH/showroom/ch/compressed/logo/logo-Club-Med.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'JmhW-sVUP-nIui',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/obertauern-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/obertauern-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'eAvt-Iws4-UOnA',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/easyjet-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/easyjet-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'n99F-7SMs-1wtc',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/easyjet-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/easyjet-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '4H6l-Hzy8-r1Y1',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'R3Z0-DS2l-9wJv',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '9AuR-6AHX-ib4l',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '6GoT-zmv0-NPKd',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'QWKF-pH7Q-0gKl',
    title: 'Wipe Away & Gallery',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/obertauern-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/obertauern-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'p9KL-3thu-Adrj',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/noe-werbung-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/noe-werbung-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'YhNX-93jY-qizL',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 's59p-VVCv-cYxk',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'ib1p-BxQh-dsMI',
    title: 'Branded Player',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'FOt0-OEiF-cOCR',
    title: 'Branded Player',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/AT/showroom/at/compressed/logo/enit-logo.png',
  },
];
