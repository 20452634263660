import { RefObject, useEffect, useState } from 'react';

export const useIsIFrameLoaded = (iframeRef: RefObject<HTMLIFrameElement>): boolean => {
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false);
  const iframeCurrent = iframeRef.current;

  useEffect(() => {
    iframeCurrent?.addEventListener('load', () => {
      return setIsIFrameLoaded(true);
    });

    return () => {
      iframeCurrent?.removeEventListener('load', () => {
        return setIsIFrameLoaded(false);
      });
    };
  }, [iframeCurrent]);
  return isIFrameLoaded;
};

export const useWindowResize = () => {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    const getSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    getSize();

    window.addEventListener('resize', getSize);

    return () => {
      window.removeEventListener('resize', getSize);
    };
  }, []);

  return size;
};
