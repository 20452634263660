import { Route } from 'type-route';
import { Spinner } from 'yoc-ui-library';

import { E_EFFECT, E_THEME } from '@common/enums';
import { E_AD_PRODUCT_KEY } from '@common/types';
import { HelmetHead } from '@components/Head';
import { Preview } from '@components/Preview';
import { TDisplayMode } from '@components/Preview/types';
import { routes } from '@navigation/router';
import NoMatchPage from '@pages/NoMatch';
import { MESSAGE_NO_SLOT_ID } from '@pages/NoMatch/constants';

import { redirectForMobile } from './helpers';
import { useAdPreview } from './hooks';

import style from './styles.module.scss';

const PreviewPageSlotId = ({ route }: { route: Route<typeof routes.preview> }) => {
  // TODO: product info is already retrieved from the API in useAdPreview
  //      refactor the logic to get product from url params.
  const {
    params: { slotId, mode, product, effect },
  } = route;

  const { adsDataCollection, previewMode, shouldRedirectForMobile } = useAdPreview(slotId, mode as TDisplayMode);

  if (shouldRedirectForMobile) {
    redirectForMobile(slotId, adsDataCollection?.previewWebsiteLayout);
    return null;
  }

  if (adsDataCollection === undefined) {
    return (
      <div
        className={style.wrapper}
        data-testid="spinner"
      >
        <Spinner size="large" />
      </div>
    );
  }

  if (adsDataCollection === null) {
    return <NoMatchPage message={MESSAGE_NO_SLOT_ID} />;
  }

  const productKeyLowerCase = adsDataCollection?.productKey.toLocaleLowerCase();
  const isAdStreamType = [E_AD_PRODUCT_KEY.YISV, E_AD_PRODUCT_KEY.YUVS].includes(
    productKeyLowerCase as E_AD_PRODUCT_KEY
  );

  return (
    <>
      <HelmetHead theme={adsDataCollection?.previewWebsiteLayout} />
      <Preview
        slotId={slotId}
        initialMode={previewMode}
        isModeSwitchAllowed={
          adsDataCollection.restrictShowcasePreview === 'mobile-and-desktop' &&
          adsDataCollection.previewType !== 'desktop'
        }
        product={isAdStreamType ? adsDataCollection?.productKey : product}
        effect={effect as E_EFFECT}
        enableScreenshot
        theme={adsDataCollection.previewWebsiteLayout ?? E_THEME.YOC}
        adData={adsDataCollection}
      />
    </>
  );
};

export default PreviewPageSlotId;
