import { Showroom } from '@common/types';

export const getSlotIdActiveCategory = (slotId: string | undefined, ads: Showroom[]): string | null => {
  if (typeof slotId !== 'string') {
    return null;
  }

  const groups = ads.filter((ad) => {
    return ad.slotId === slotId;
  });

  return groups[0]?.category;
};
