import React, { forwardRef, IframeHTMLAttributes, useCallback } from 'react';

import { MOBILE_PREVIEW_INIT_MESSAGE } from './constants';
import { adjustMobilePreviewViewport } from './helpers';
import { usePostMessageHandler } from './hooks';
import { IScrollbarWidthMessage } from './types';

export const Iframe = forwardRef<
  HTMLIFrameElement,
  { onError: () => void } &
    Omit<IframeHTMLAttributes<HTMLIFrameElement>,
    'onError'
  >
    >(({ onLoad, onError, ...iframeProps }, ref) => {
      const processMessage = useCallback(
        (ev: MessageEvent<IScrollbarWidthMessage>) => {
          const iframe = (ref as React.MutableRefObject<HTMLIFrameElement>).current;

          if (
            iframe &&
        ev.source === iframe.contentWindow &&
        ev.data &&
        typeof ev.data === 'object' &&
        ev.data.msg === MOBILE_PREVIEW_INIT_MESSAGE
          ) {
            const fakeScroll = ev.data.scrollbarWidth > 0;

            adjustMobilePreviewViewport(iframe.contentWindow!, {
              fakeScroll,
              mobileLayout: true,
              adType: 'ybc',
            });
            // check for fake-scroll only if tab is active (to avoid width calculations on background tabs)
            if (!document.hidden && fakeScroll) {
              iframe.style.width = `calc(100% + ${ev.data.scrollbarWidth}px)`;
            }
          }
        },
        [ref]
      );

      usePostMessageHandler(processMessage);

      return (
        <iframe
          title="Iframe"
          ref={ref}
          onError={onError}
          {...iframeProps}
          data-cmp-ab="1" // In order to prevent CMP script blocking iframe loading
        />
      );
    });

Iframe.displayName = 'Iframe';
