import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Carousel as Slider, CarouselRef, CarouselSettings } from 'yoc-ui-library';

import { ArrowButton } from '@components/ArrowButton';

import { CAROUSEL_MIN_HEIGHT, CAROUSEL_SETTINGS_DEFAULT } from './configs';
import { ICarouselProps } from './types';

import './styles.scss';
import style from './styles.module.scss';

export const Carousel: FunctionComponent<ICarouselProps> = (props) => {
  const { slides, onChange, isVerticalMode = false } = props;
  const carouselRef = useRef<CarouselRef>(null);
  const carouselDotsRef = useRef<HTMLDivElement>(null);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    if (onChange) {
      onChange(currentSlideIndex);
    }
  }, [currentSlideIndex, onChange]);

  useEffect(() => {
    const updateGallerySize = () => {
      const carouselDotsHeight = carouselDotsRef.current?.clientHeight ?? 0;
      const scale = (window.innerHeight - carouselDotsHeight) / CAROUSEL_MIN_HEIGHT;

      if (carouselRef.current?.innerSlider?.list) {
        carouselRef.current.innerSlider.list.style.transform = `scale(${scale >= 1 ? 1 : scale})`;
      }
    };

    window.addEventListener('resize', updateGallerySize);

    updateGallerySize();

    return () => {
      window.removeEventListener('resize', updateGallerySize);
    };
  }, []);

  if (!slides || slides.length === 0) {
    return null;
  }

  const goToNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const goToPrevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  let carouselSettings: CarouselSettings = {
    ...CAROUSEL_SETTINGS_DEFAULT,
    draggable: false,
    afterChange: (index: number) => {
      setCurrentSlideIndex(index);
    },
    appendDots: (dots: ReactNode) => {
      return (
        <div ref={carouselDotsRef}>
          <ArrowButton
            direction={isVerticalMode ? 'top' : 'left'}
            disabled={currentSlideIndex === 0}
            onClick={goToPrevSlide}
            scale={1.5}
          />

          <ul>{dots}</ul>
          {isVerticalMode && <i className="touch-icon" />}

          <ArrowButton
            direction={isVerticalMode ? 'bottom' : 'right'}
            disabled={currentSlideIndex === slides.length - 1}
            onClick={goToNextSlide}
            scale={1.5}
          />
        </div>
      );
    },
  };

  if (isVerticalMode) {
    carouselSettings = {
      ...carouselSettings,
      vertical: true,
      variableWidth: false,
      centerPadding: '0',
    };
  }

  return (
    <Slider
      {...carouselSettings}
      ref={carouselRef}
      className={cn(style.container, { 'vertical-mode': isVerticalMode })}
    >
      {slides.map((item, index) => {
        return (
          <div
            className={style.preview}
            key={index}
          >
            {item}
          </div>
        );
      })}
    </Slider>
  );
};
